module.exports = {
    PROCESS_ELT_REPORT: 1,
    PROCESS_EXPORT_REPORT_TO_GCS: 2,
    PROCESS_DOWNLOAD_REPORT: 3,

    VIEW_ABNORMALBET_LISTING: 4,
    VIEW_ABNORMALBET_ALERT_LISTING: 5,
    EXPORT_ABNORMALBET_LISTING: 6,
    EXPORT_ABNORMALBET_ALERT_LISTING: 7,

    VIEW_USER: 19,
    ADD_USER: 20,
    EDIT_USER: 21,
    RESET_USER_PASSWORD: 22,

    VIEW_ROLE: 23,
    ADD_ROLE: 24,
    EDIT_ROLE: 25,

    VIEW_FAIP_LISTING: 30,
    VIEW_FAIP_ALERT_LISTING: 31,
    EXPORT_FAIP_LISTING: 32,
    EXPORT_FAIP_ALERT_LISTING: 33,

    VIEW_FAIPWHITELIST: 35,
    ADD_FAIPWHITELIST: 36,
    EDIT_FAIPWHITELIST: 37,

    ONOFF_OBFUSCATEDRESPONSE: 38,

    OPS_GENERAL_SETTING: 40,
    COMPLIANCE_GENERAL_SETTING: 41,

    VIEW_FEATUREONOFF_SETTING: 43,
    EDIT_FEATUREONOFF_SETTING: 44,

    VIEW_AGB_LISTING: 47,
    VIEW_AGB_ALERT_LISTING: 48,
    EXPORT_AGB_LISTING: 49,
    EXPORT_AGB_ALERT_LISTING: 50,

    VIEW_MEMBERBEHAVIOURCHANGE_LISTING: 52,
    EXPORT_MEMBERBEHAVIOURCHANGE_LISTING: 53,

    VIEW_REGULARMEMBERTAGGING_LISTING: 55,
    EXPORT_REGULARMEMBERTAGGING_LISTING: 56,

    VIEW_MEMBERGROUPING_LISTING: 58,
    EXPORT_MEMBERGROUPING_LISTING: 59,

    VIEW_FAMEMBER_LISTING: 62,
    VIEW_FAMEMBER_ALERT_LISTING: 63,
    EXPORT_FAMEMBER_LISTING: 64,
    EXPORT_FAMEMBER_ALERT_LISTING: 65,
    EDIT_FAMEMBER_ALERT_LISTING: 203,
    EDIT_FAMEMBER_LISTING: 202,

    VIEW_FAMEMBERWHITELIST: 67,
    ADD_FAMEMBERWHITELIST: 68,
    EDIT_FAMEMBERWHITELIST: 69,

    VIEW_TABLEAU_FAIP: 72,

    MONITORING: 73,

    MEMBERCATEGORYWHITELIST: 74,
    VIEW_MEMBERCATEGORYWHITELIST: 75,
    ADD_MEMBERCATEGORYWHITELIST: 76,
    EDIT_MEMBERCATEGORYWHITELIST: 77,

    VIEW_COMBOGHOSTFULLCOVER_LISTING: 80,
    VIEW_COMBOGHOSTFULLCOVER_ALERT_LISTING: 81,
    EXPORT_COMBOGHOSTFULLCOVER_LISTING: 82,
    EXPORT_COMBOGHOSTFULLCOVER_ALERT_LISTING: 83,

    VIEW_COMBOGHOSTHIGHEXPOSURE_LISTING: 86,
    VIEW_COMBOGHOSTHIGHEXPOSURE_ALERT_LISTING: 87,
    EXPORT_COMBOGHOSTHIGHEXPOSURE_LISTING: 88,
    EXPORT_COMBOGHOSTHIGHEXPOSURE_ALERT_LISTING: 89,

    VIEW_TABLEAU_MONITORINGDASHBOARD: 92,

    VIEW_MEMBERSUMMARY_LISTING: 94,
    EXPORT_MEMBERSUMMARY_LISTING: 95,

    ACTION_REGULARMEMBERTAGGING: 96,

    VIEW_PROFILEGROUP: 98,
    ADD_PROFILEGROUP: 99,
    EDIT_PROFILEGROUP: 100,

    VIEW_OPS_ALERT: 101,

    VIEW_ONESIDEDEVENT_LISTING: 104,
    VIEW_ONESIDEDEVENT_ALERT_LISTING: 105,
    EXPORT_ONESIDEDEVENT_LISTING: 106,
    EXPORT_ONESIDEDEVENT_ALERT_LISTING: 107,

    VIEW_AGB_MEMBERSUMMARY_LISTING: 109,
    EXPORT_AGB_MEMBERSUMMARY_LISTING: 110,

    VIEW_MEMBER_LISTING: 113,
    EDIT_MEMBER_LISTING: 114,
    EXPORT_MEMBER_LISTING: 115,

    VIEW_INSTANTBET_ALERT_LISTING: 117,

    VIEW_EVENTDASHBOARD_LISTING: 121,
    EXPORT_EVENTDASHBOARD_LISTING: 122,

    GBSYSTEM_MONITORING: 123,
    BETREJECTION_MONITORING: 124,

    VIEW_MEMBERCOOKIEGROUPING_LISTING: 125,
    EXPORT_MEMBERCOOKIEGROUPING_LISTING: 126,

    VIEW_FAMEMBERBLACKLIST: 128,
    ADD_FAMEMBERBLACKLIST: 129,
    EDIT_FAMEMBERBLACKLIST: 130,

    MEMBER_BETREJECTION_MONITORING: 131,

    VIEW_ELTFILE_DASHBOARD: 132,

    VIEW_ELTJOBSTATUS_DASHBOARD: 133,

    VIEW_DATAMAPPING: 135,
    ADD_DATAMAPPING: 136,
    EDIT_DATAMAPPING: 137,

    PROCESS_SFRAWDATAEXTRACTION: 139,

    VIEW_RISKSUMMARY: 141,
    EXPORT_RISKSUMMARY: 142,

    VIEW_EVENTSETTLEMENT: 144,
    EXPORT_EVENTSETTLEMENT: 145,

    VIEW_THEORETICALWINLOSSMARGIN: 147,
    EXPORT_THEORETICALWINLOSSMARGIN: 148,

    VIEW_EVENTUPTIMEMONITORING: 150,
    EXPORT_EVENTUPTIMEMONITORING: 151,

    VIEW_INTERNALAUDIT: 153,
    EXPORT_INTERNALAUDIT: 154,

    ENABLE_2FA: 155,
    RESET_2FA: 156,

    VIEW_WAGERREPORT: 159,
    EXPORT_WAGERREPORT: 160,

    VIEW_MEMBERPERFORMANCE: 162,
    EXPORT_MEMBERPERFORMANCE: 163,

    VIEW_MEMBERPROFILING: 165,
    EXPORT_MEMBERPROFILING: 166,

    VIEW_MEMBERMASSUPDATE: 168,
    EDIT_MEMBERMASSUPDATE: 169,

    VIEW_MEMBERDASHBOARD: 171,
    EXPORT_MEMBERDASHBOARD: 172,

    VIEW_SCOREPROFILE: 174,
    ADD_SCOREPROFILE: 175,
    EDIT_SCOREPROFILE: 176,

    VIEW_BETATTEMPT_ALERT_LISTING: 185,
    EXPORT_BETATTEMPT_ALERT_LISTING: 186,

    VIEW_MLTAGGINGWAGER: 188,
    EXPORT_MLTAGGINGWAGER: 189,

    VIEW_ARBERMEMBERSUMMARY_LISTING: 191,
    EXPORT_ARBERMEMBERSUMMARY_LISTING: 192,

    VIEW_ARBERBETREPORT_LISTING: 194,
    EXPORT_ARBERBETREPORT_LISTING: 195,

    VIEW_ARBERBET_ALERT_LISTING: 197,
    EXPORT_ARBERBET_ALERT_LISTING: 198,

    VIEW_BETATTEMPTREPORT_LISTING: 200,
    EXPORT_BETATTEMPTREPORT_LISTING: 201,

    VIEW_OPS_GENERAL_SETTING: 204,
    EDIT_OPS_GENERAL_SETTING: 205,
    VIEW_COMPLIANCE_GENERAL_SETTING: 206,
    EDIT_COMPLIANCE_GENERAL_SETTING: 207,
    IT_SUPPORT_GENERAL_SETTING: 208,

    VIEW_EVENT_RECON_REPORT: 211,
    EXPORT_EVENT_RECON_REPORT: 212,

    VIEW_MLTAGGINGUI: 214,
    EXPORT_MLTAGGINGUI: 215,

    VIEW_MEMBERCATEGORYSETTING: 220,
    ADD_MEMBERCATEGORYSETTING: 221,
    EDIT_MEMBERCATEGORYSETTING: 222,

    VIEW_AGBMEMBERSUMMARY_RECON_REPORT: 224,
    EXPORT_AGBMEMBERSUMMARY_RECON_REPORT: 225,

    ALERT_INTERNAL_AUDIT: 226,
    VIEW_NOTIFICATION: 227,

    VIEW_WAGER_DETAIL: 999,

    VIEW_MEMBERPROFILINGASSIGNMENT: 229,
    EXPORT_MEMBERPROFILINGASSIGNMENT: 230,
    AUDIT_MEMBERPROFILINGASSIGNMENT: 231,

    VIEW_USERAUDITLOG: 232,
    EXPORT_USERAUDITLOG: 233,
};
