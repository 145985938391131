import React, { useState, useRef, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { authHelper } from '../utils/authHelper';
import { loadFromLocalStorage } from '../utils/storageHelper';
import storageConst from '../constants/storage';
import NotificationPopup from '../components/Common/NotificationPopup';
import apiGetDataReducer, { initialApiGetState } from '../reducers/Common/apiGetDataReducer';
import { apiGBDashboard } from '../apis/apiGBDashboard';
import SocketConnection from '../utils/socket/SignalRConnection';
import { CommonActionCreators } from '../actions/Common';
import { NOTIFICATION } from '../constants/signalrConst';
import permissionConst from '../constants/permissionConst';

const Header = () => {
    const history = useHistory();

    const [currentUser] = useState(loadFromLocalStorage(storageConst.CURRENT_USER));
    const [notificationDataState, dispatch] = useReducer(apiGetDataReducer, initialApiGetState);
    const notificationPermission = authHelper.checkPermission(permissionConst.VIEW_NOTIFICATION);
    const [showNotification, setShowNotification] = useState(false);
    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
    const popupRef = useRef(null);

    const socketRef = useRef(null);
    // Only initialize the socket once on mount
    if (!socketRef.current) {
        socketRef.current = SocketConnection(NOTIFICATION);
    }
    const socket = socketRef.current;

    useEffect(() => {
        // Add event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);

        if (notificationPermission) getLatestNotificationDataAsync();

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleReceiveMessage = (jsonString) => {
        console.log(jsonString);
        const userIDList = JSON.parse(jsonString);
        if (Array.isArray(userIDList) && userIDList.some((item) => item.userID === currentUser.userId)) {
            getLatestNotificationDataAsync();
        }
    };

    useEffect(() => {
        if (notificationPermission) {
            socket.on(NOTIFICATION, (data) => {
                handleReceiveMessage(data);
            });
            return () => {
                socket.off(NOTIFICATION);
            };
        }
    }, [handleReceiveMessage]);

    const handleLogout = async () => {
        console.log('logging out...');
        await authHelper.logout();
        history.push('/login');
    };

    const toogleShowNotification = (e) => {
        e.preventDefault();

        const x = e.clientX - 250;
        const y = e.clientY;

        setPopupPosition({ x, y });
        setShowNotification(!showNotification);

        apiGBDashboard.createNotificationUserList({ userID: currentUser.userId });

        getLatestNotificationDataAsync(e);
    };

    const handleClickOutside = (e) => {
        if (popupRef.current && !popupRef.current.contains(e.target)) {
            setShowNotification(false); // Hide the popup if clicked outside
            getLatestNotificationDataAsync(e);
        }
    };

    const getLatestNotificationDataAsync = async (e) => {
        try {
            if (e) e.preventDefault();

            const notificationResponse = await apiGBDashboard.getCurrentNotificationList({
                isDateFilter: false,
                userID: currentUser.userId,
            });

            if (notificationResponse.result) {
                const notificationData = notificationResponse.data;

                dispatch(CommonActionCreators.GET_LIST_SUCCESS(notificationData, notificationResponse.totalCount));
            } else {
                dispatch(CommonActionCreators.GET_LIST_FAILED('Error fetching latest notification list!'));
            }
        } catch (error) {
            console.log(`Error on get LatestNotificationDataAsync: ${error}`);
        }
    };

    return (
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                <i className="fa fa-bars"></i>
            </button>

            <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown no-arrow d-sm-none">
                    <button type="button" className="nav-link dropdown-toggle" href="#" id="searchDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-search fa-fw"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
                        <form className="form-inline mr-auto w-100 navbar-search">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control bg-light border-0 small"
                                    placeholder="Search for..."
                                    aria-label="Search"
                                    aria-describedby="basic-addon2"
                                />
                                <div className="input-group-append">
                                    <button className="btn btn-primary" type="button">
                                        <i className="fas fa-search fa-sm"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </li>

                {notificationPermission && (
                    <div className="header-notification" onClick={toogleShowNotification}>
                        <div className="notification-icon">
                            <div className="fa fa-bell fa-2x" />
                            <div className={notificationDataState?.totalRow > 0 ? 'notification-counter' : ''}>
                                {notificationDataState?.totalRow > 0 && notificationDataState.totalRow}
                            </div>
                        </div>
                    </div>
                )}

                {showNotification && (
                    <div
                        className="notification-popup"
                        ref={popupRef}
                        style={{
                            top: popupPosition.y + 'px',
                            left: popupPosition.x + 'px',
                        }}
                    >
                        <NotificationPopup data={notificationDataState.data} />
                    </div>
                )}

                <div className="topbar-divider d-none d-sm-block"></div>

                <li className="nav-item dropdown no-arrow">
                    <button
                        type="button"
                        className="btn btn-link nav-link dropdown-toggle"
                        href="#"
                        id="userDropdown"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <i className="fa fa-user"></i> &nbsp;
                        <span className="mr-2 d-none d-lg-inline text-gray-600" id="username">
                            {currentUser === null ? '' : currentUser.name}
                        </span>
                        {/* <img className="img-profile rounded-circle" src="https://source.unsplash.com/QAB-WJcbgJk/60x60" /> */}
                        <i className="fa fa-angle-down"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                        <a className="dropdown-item" href="/account/updatePassword">
                            <i className="fa fa-key fa-sm fa-fw mr-2 text-gray-400"></i>
                            Update Password
                        </a>
                        <button type="button" className="dropdown-item" onClick={handleLogout} href="#">
                            <i className="fa fa-power-off fa-sm fa-fw mr-2 text-gray-400"></i>
                            Logout
                        </button>
                    </div>
                </li>
            </ul>
        </nav>
    );
};

export default Header;
