import { type } from 'jquery';
import * as ActionTypes from './constants';

// Get List
export const PRE_GET_LIST = (payload) => ({
    type: ActionTypes.PRE_GET_LIST,
    payload,
});
export const GET_LIST_SUCCESS = (data, totalCount = 0, page = 0, pageSize = 0, selectedDayRanges, lastUpdated) => ({
    type: ActionTypes.GET_LIST_SUCCESS,
    data: data,
    totalCount: totalCount,
    page: page,
    pageSize: pageSize,
    selectedDayRanges: selectedDayRanges,
    lastUpdated: lastUpdated,
});

export const GET_LIST_SUCCESS_WITHRAWDATA = (response) => ({
    type: ActionTypes.GET_LIST_SUCCESS_WITHRAWDATA,
    response,
});

export const GET_LIST_FAILED = (response) => ({
    type: ActionTypes.GET_LIST_FAILED,
    response,
});
export const PAGINATION_CHANGED = (page, pageSize) => ({
    type: ActionTypes.PAGINATION_CHANGED,
    page: page,
    pageSize: pageSize,
});

export const SET_VALIDATEERRORS = (response) => ({
    type: ActionTypes.SET_VALIDATEERRORS,
    response,
});

export const VALIDATION_FAILED = (response) => ({
    type: ActionTypes.VALIDATION_FAILED,
    response,
});

//#region Common Modal visible
export const OPEN_MODAL = (response) => ({
    type: ActionTypes.OPEN_MODAL,
    response,
});

export const CLOSE_MODAL = (response) => ({
    type: ActionTypes.CLOSE_MODAL,
    response,
});

export const OPEN_MODAL_FAILED = (response) => ({
    type: ActionTypes.OPEN_MODAL_FAILED,
    response,
});

export const OPEN_SECONDMODAL = (response) => ({
    type: ActionTypes.OPEN_SECONDMODAL,
    response,
});

export const CLOSE_SECONDMODAL = (response) => ({
    type: ActionTypes.CLOSE_SECONDMODAL,
    response,
});

export const OPEN_SECONDMODAL_FAILED = (response) => ({
    type: ActionTypes.OPEN_SECONDMODAL_FAILED,
    response,
});
//#endregion

export const OPEN_EXPORTDIALOGMODAL = (response) => ({
    type: ActionTypes.OPEN_EXPORTDIALOGMODAL,
    response,
});

export const CLOSE_EXPORTDIALOGMODAL = (response) => ({
    type: ActionTypes.CLOSE_EXPORTDIALOGMODAL,
    response,
});

export const SET_DATA = (response) => ({
    type: ActionTypes.SET_DATA,
    response,
});

export const RESET_ERRMSG = (response) => ({
    type: ActionTypes.RESET_ERRMSG,
    response,
});

//#region Add/Edit Modal
export const OPEN_ADDEDITVIEW_MODAL = (response) => ({
    type: ActionTypes.OPEN_ADDEDITVIEW_MODAL,
    response,
});
export const CLOSE_ADDEDITVIEW_MODAL = (response) => ({
    type: ActionTypes.CLOSE_ADDEDITVIEW_MODAL,
    response,
});
//#endregion

//#region Audit Modal
export const OPEN_AUDIT_MODAL = (response) => ({
    type: ActionTypes.OPEN_AUDIT_MODAL,
    response,
});
export const SETUP_AUDIT_MODAL = (response) => ({
    type: ActionTypes.SETUP_AUDIT_MODAL,
    response,
});
export const OPEN_AUDIT_MODAL_FAILED = (response) => ({
    type: ActionTypes.OPEN_AUDIT_MODAL_FAILED,
    response,
});
export const CLOSE_AUDIT_MODAL = (response) => ({
    type: ActionTypes.CLOSE_AUDIT_MODAL,
    response,
});
//#endregion

export const SET_MODAL_DATA = (data) => ({
    type: ActionTypes.SET_MODAL_DATA,
    data,
});

export const ADDEDIT_ITEM = () => ({
    type: ActionTypes.ADDEDIT_ITEM,
});

export const ADDEDIT_ITEM_SUCCESS = (response) => ({
    type: ActionTypes.ADDEDIT_ITEM_SUCCESS,
    response,
});

export const ADDEDIT_ITEM_FAILED = (response) => ({
    type: ActionTypes.ADDEDIT_ITEM_FAILED,
    response,
});

export const SET_INPUTVALUE = (response) => ({
    type: ActionTypes.SET_INPUTVALUE,
    response,
});

export const SET_SINGLEINPUTVALUE = (response) => ({
    type: ActionTypes.SET_SINGLEINPUTVALUE,
    response,
});

export const INIT_DROPDOWNLIST = (response) => ({
    type: ActionTypes.INIT_DROPDOWNLIST,
    response,
});

export const RESET_SEARCHSTATE = () => ({
    type: ActionTypes.RESET_SEARCHSTATE,
});

// 2FA
export const CLOSE_2FATOKEN_MODAL = () => ({
    type: ActionTypes.CLOSE_2FATOKEN_MODAL,
});

export const OPEN_2FATOKEN_MODAL = (payload) => ({
    type: ActionTypes.OPEN_2FATOKEN_MODAL,
    payload,
});

export const VERIFY_TWOFATORTOKEN = () => ({
    type: ActionTypes.VERIFY_TWOFATORTOKEN,
});

export const VERIFY_TWOFATORTOKEN_DONE = () => ({
    type: ActionTypes.VERIFY_TWOFATORTOKEN_DONE,
});

// common tab selection
export const SET_SELECTED_TAB = (response) => ({
    type: ActionTypes.SET_SELECTED_TAB,
    module: response.module,
});

// Post-Message Modal
export const CLOSE_MESSAGE_MODAL = () => ({
    type: ActionTypes.CLOSE_MESSAGE_MODAL,
});

// export
export const OPEN_EXPORT_MODAL = () => ({
    type: ActionTypes.OPEN_EXPORT_MODAL,
});

export const CLOSE_EXPORT_MODAL = () => ({
    type: ActionTypes.CLOSE_EXPORT_MODAL,
});

export const PRE_EXPORT_LIST = (payload) => ({
    type: ActionTypes.PRE_EXPORT_LIST,
    payload,
});

export const EXPORT_LIST_SUCCESS = (response) => ({
    type: ActionTypes.EXPORT_LIST_SUCCESS,
    response,
});

export const EXPORT_LIST_FAILED = (response) => ({
    type: ActionTypes.EXPORT_LIST_FAILED,
    response,
});

export const SET_EXPORTLIST = (response) => ({
    type: ActionTypes.SET_EXPORTLIST,
    response,
});

export const SET_EXPORTDATA = (response) => ({
    type: ActionTypes.SET_EXPORTDATA,
    response,
});

export const EXPORT_SET_ISOPENEXPORT = (response) => ({
    type: ActionTypes.EXPORT_SET_ISOPENEXPORT,
    response,
});

//#region Alert
export const SET_ALERTSHOWTABLE = (response) => ({
    type: ActionTypes.SET_ALERTSHOWTABLE,
    response,
});

export const SET_FREEZEDATALIST = (response) => ({
    type: ActionTypes.SET_FREEZEDATALIST,
    response,
});

export const SET_SELECTEDDATALIST = (response) => ({
    type: ActionTypes.SET_SELECTEDDATALIST,
    response,
});

export const SET_ALERTPAUSE = (response) => ({
    type: ActionTypes.SET_ALERTPAUSE,
    response,
});
//#endregion

//#region Bet Attempt Alert

export const SET_COMPETITIONEVENTLIST = (response) => {
    return {
        type: ActionTypes.SET_COMPETITIONEVENTLIST,
        response: response,
    };
};

export const RESET_COMPETITIONEVENTLIST = (response) => ({
    type: ActionTypes.RESET_COMPETITIONEVENTLIST,
    response,
});

export const SET_SELECTEDEVENTIDS = (response) => ({
    type: ActionTypes.SET_SELECTEDEVENTIDS,
    response,
});

export const SET_SELECTEDCOMPETITIONEVENTIDS = (response) => ({
    type: ActionTypes.SET_SELECTEDCOMPETITIONEVENTIDS,
    response,
});

export const SET_COMPETITIONLIST = (response) => ({
    type: ActionTypes.SET_COMPETITIONLIST,
    response,
});
//#endregion

export const CLEAR_INPUTVALUE = (response) => ({
    type: ActionTypes.CLEAR_INPUTVALUE,
    response,
});
