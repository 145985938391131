import apis from '../apis';
import { apiGBDashboard } from '../apis/apiGBDashboard';
import storageConst from '../constants/storage';
import { removeFromLocalStorage, saveToLocalStorage, loadFromLocalStorage } from './storageHelper';
import Cookies from 'universal-cookie';

export const cookies = new Cookies();

async function login(username, password) {
    const response = await apis.login({ username, password });
    if (response.msg) {
        console.log(response.msg);
        return response.msg;
    }
    console.log('Login successful!');
    //const expiration = process.env.DB_ENV === 'testing' ? 1000 : 604800000;
    saveToLocalStorage(storageConst.CURRENT_USER, response);

    if (!response.isGlobal2FAEnabled) {
        saveToLocalStorage(storageConst.AUTH_ERROR, false);
        const sortedList = response.permissions.sort((a, b) => a.ordering - b.ordering);
        const uiPermission = sortedList.map((x) => ({
            id: x.permissionID,
            name: x.permissionName,
            display: x.displayName,
            level: x.permissionLevel,
            url: x.pageUrl,
            pID: x.parentPermissionID,
            remark: x.remarks,
        }));
        saveToLocalStorage(storageConst.MENU, uiPermission);
    }

    return true;
}

async function verify2fa(token, uniqueKey = null, isNewSetup2FA = false, isLogin = false) {
    try {
        const userInfo = loadFromLocalStorage(storageConst.CURRENT_USER);

        console.log(`unique key: ${uniqueKey === null || uniqueKey === '' ? userInfo.uniqueKey : uniqueKey}`);
        const response = await apis.verify2FAToken({
            userId: userInfo.userId,
            uniqueKey: uniqueKey === null || uniqueKey === '' ? userInfo.uniqueKey : uniqueKey,
            token: token,
            currentUser: userInfo.name,
            isNewSetup2FA: isNewSetup2FA,
            isLogin: isLogin,
        });

        if (response.result === true) {
            if (isLogin) {
                removeFromLocalStorage(storageConst.CURRENT_USER);
                saveToLocalStorage(storageConst.CURRENT_USER, response);
                saveToLocalStorage(storageConst.AUTH_ERROR, false);
                const sortedList = response.permissions.sort((a, b) => a.ordering - b.ordering);
                const uiPermission = sortedList.map((x) => ({
                    id: x.permissionID,
                    name: x.permissionName,
                    display: x.displayName,
                    level: x.permissionLevel,
                    url: x.pageUrl,
                    pID: x.parentPermissionID,
                    remark: x.remarks,
                }));

                saveToLocalStorage(storageConst.MENU, uiPermission);
            }

            return true;
        } else {
            return false;
        }
    } catch (error) {
        alert(error.message);
        return false;
    }
}

async function logout(isSessionExpired = false) {
    const userInfo = loadFromLocalStorage(storageConst.CURRENT_USER);
    // post api to track the user activity
    if (userInfo) {
        await apiGBDashboard.insertUserActivityLog({
            fieldType: 'LogOut',
            targetName: isSessionExpired ? 'session expired' : 'manual sign-out',
        });
    }

    // remove user from local storage to log user out
    removeFromLocalStorage(storageConst.CURRENT_USER);
    removeFromLocalStorage(storageConst.MENU);
    removeFromLocalStorage(storageConst.GRID_COLUMN_ORDER);
}

function getUserPermission() {
    if (!loadFromLocalStorage(storageConst.MENU)) return [];

    return loadFromLocalStorage(storageConst.MENU);
}

function getMenuPermission() {
    if (!loadFromLocalStorage(storageConst.MENU)) return [];

    const permission = loadFromLocalStorage(storageConst.MENU);
    return permission.filter((x) => x.level <= 2);
}

function checkPermission(permissionID) {
    if (!loadFromLocalStorage(storageConst.MENU)) return [];

    const permission = loadFromLocalStorage(storageConst.MENU);
    if (permission.map((x) => x.id).includes(permissionID)) return true;

    return false;
}

function getTableauLinkFromMenuPermission(permissionID) {
    if (!loadFromLocalStorage(storageConst.MENU)) return [];

    const permission = loadFromLocalStorage(storageConst.MENU);
    const uiPermission = permission.find(function (x) {
        return x.id === permissionID;
    });

    return uiPermission == null ? '' : uiPermission.url;
}

export const authHelper = {
    login,
    verify2fa,
    logout,
    getUserPermission,
    getMenuPermission,
    checkPermission,
    getTableauLinkFromMenuPermission,
};
