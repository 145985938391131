module.exports = {
    ProductCode: {
        Sportbook: 0,
    },
    Module: {
        All: -1,
        NA: 0,
        AbnormalBet: 1,
        FAIP: 2,
        MemberGrouping_Device: 3,
        FAMember: 4,
        MemberTagging: 5,
        ComboGhostFullCover: 6,
        ComboGhostHighExposure: 7,
        ComboGhostFullCoverWagerDetail: 8,
        ComboGhostFullCoverMinusOneWagerDetail: 9,
        ComboGhostHighExposureWagerDetail: 10,
        MemberSummaryBetDateDetail: 11,
        MemberBehaviourChange: 12,
        OneSidedEvent: 13,
        MemberListing: 14,
        MemberLogMemberListing: 15,
        OneSidedEventWagerDetail: 16,
        MemberLogMemberTagging: 17,
        MemberBehaviourChangeCriteriaHit: 18,
        AfterGoalBuyer: 19,
        EventDashboardWagerDetail: 20,
        MemberGrouping_MembersCookie: 21,
        ManagementReport_Wager: 22,
        ManagementReport_Event: 23,
        RiskSummarySettledWager: 24,
        RiskSummarySettledWagerDetail: 25,
        RiskSummaryCancelledWager: 26,
        RiskSummaryCancelledWagerDetail: 27,
        EventSettlementReport: 28,
        TheoreticalWinlossMargin: 29,
        EventUptimeMonitoring: 30,
        InternalAudit: 31,
        InternalAuditWager: 32,
        WagerReport: 33,
        MemberPerformanceMemberDetail: 34,
        MemberProfilingMemberDetail: 35,
        AfterGoalBuyerMemberSummary: 36,
        MemberProfileGroup: 37,
        MemberMassUpdate: 38,
        MemberDashboardSystemRejectionDetail: 39,
        MemberDashboardDailyPerformance: 40,
        MemberDashboardCancelledWager: 41,
        MemberDashboardSystemRejection: 42,
        MemberDashboardWagerDetail: 43,
        MemberDashboardMonthlyPerformance: 44,
        MemberDashboardSportPerformance: 45,
        MemberDashboardComboPerformance: 46,
        MemberDashboardMemberWinLossDiff: 47,
        MemberDashboardBetTypePerformance: 48,
        MemberDashboardCompetitionPerformance: 49,
        MemberDashboardMarketTypePerformance: 50,
        MemberDashboardWagerSourcePerformance: 51,
        MemberDashboardTierPerformance: 52,
        MemberDashboardLatestRemarksPerformance: 53,
        MemberDashboardScoreActionPerformance: 60,
        MemberBetRejection: 55,
        ScoreProfile: 56,
        ScoreProfileDetail: 57,
        MemberArberRule: 58, // for score profile
        SF_MemberSync: 59,
        MLTaggingWager: 61,
        ArberMember: 62,
        ArberBetReport: 63,
        BetAttemptAlert: 64,
        BetAttemptByEvent: 65,
        BetAttemptByBet: 66,
        BetAttemptSystemRejectionDetail: 67,
        MemberDashboardStakeRange: 68,
        MemberDashboardIPAddress: 69,
        MemberDashboardOverallPerformance: 70,
        MemberDashboardBetTogether: 71,
        MemberDashboardBetTogetherDetail: 72,
        InstantBet: 75,
        EventReconReport: 76,
        MLTaggingUI: 79,
        BetAttemptAlertByEvent: 81,
        MemberCategoryGroup: 82,
        AGBMemberSummaryReconReport: 83,
        ProfileGroupPriority: 87,
        MemberProfilingAssignment: 88,
        UserListing: 89,
        UserAuditLog: 90,
        UserAuditLogByUser: 91,
    },
    AGBBetStatus: {
        Open: 0,
        isCancelled: 1,
        isSettled: 2,
    },
    InternalAuditExportType: {
        CancelledUnCancelledWager: 0,
        CancelledWager: 1,
        UnCancelledWager: 2,
        MemberLevel: 3,
        CancelledUnCancelledReason: 4,
    },
};
