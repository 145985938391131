import { get, post } from '../utils/fetch';
import { formatDate } from '../utils/common';

const baseUrl = process.env.REACT_APP_DASHBOARD_API;

console.log(`api baseUrl: ${baseUrl}`);

const api = {
    //#region Support
    getELTResultByDate: (data) => {
        return post(`${baseUrl}/api/getELTResultByDate`, data);
    },

    getLiveWagerDiscrepancyList: (data) => {
        return post(`${baseUrl}/api/getLiveWagerDiscrepancyList`, data);
    },

    getJobInfoDetailList: () => {
        return post(`${baseUrl}/api/getJobInfoDetailList`);
    },

    getPiwikLogList: () => {
        return post(`${baseUrl}/api/getPiwikLogList`);
    },

    getGCSFileList: (data) => {
        return post(`${baseUrl}/api/getGCSFileList`, data);
    },

    getJobScheduleStatusList: () => {
        return get(`${baseUrl}/api/getJobScheduleStatusList`);
    },
    getOfflineExportUsageList: (data) => {
        return post(`${baseUrl}/api/getOfflineExportUsageList`, data);
    },
    //#endregion

    //#region Monitoring
    getSystemSettingListForMonitoring: () => {
        return post(`${baseUrl}/api/getSystemSettingListForMonitoring`);
    },
    getBetRejectionList: (data) => {
        return post(`${baseUrl}/api/getBetRejectionList`, data);
    },
    getMemberBetRejectionHitPatternList: () => {
        return get(`${baseUrl}/api/getMemberBetRejectionHitPatternList`);
    },
    getMemberBetRejectionList: (data) => {
        return post(`${baseUrl}/api/getMemberBetRejectionList`, data);
    },
    getMemberBetRejectionListForExport: (data) => {
        return post(`${baseUrl}/api/getMemberBetRejectionListForExport`, data);
    },
    //#endregion

    //#region Common
    getSportList: () => {
        return get(`${baseUrl}/api/getSportsList`);
    },
    getCompanyList: () => {
        return get(`${baseUrl}/api/getCompanyList`);
    },
    getCompanyListWithGroup: (data) => {
        return post(`${baseUrl}/api/getCompanyListWithGroup`, data);
    },
    getMemberCategoryList: () => {
        return get(`${baseUrl}/api/getMemberCategoryList`);
    },
    getMemberSpreadGroupList: () => {
        return get(`${baseUrl}/api/getMemberSpreadGroupList`);
    },
    getMemberSettingProfileList: () => {
        return get(`${baseUrl}/api/getMemberSettingProfileList`);
    },
    getMemberIsAdvisedList: () => {
        return get(`${baseUrl}/api/getMemberIsAdvisedList`);
    },
    getMemberIsHousePlayerList: () => {
        return get(`${baseUrl}/api/getMemberIsHousePlayerList`);
    },
    getMarketList: () => {
        return get(`${baseUrl}/api/getMarketList`);
    },
    getPeriodCodeList: () => {
        return get(`${baseUrl}/api/getPeriodCodeList`);
    },
    getBetTypeList: () => {
        return get(`${baseUrl}/api/getBetTypeList`);
    },
    getWagerTypeList: () => {
        return get(`${baseUrl}/api/getWagerTypeList`);
    },
    getWagerStatusList: () => {
        return get(`${baseUrl}/api/getWagerStatusList`);
    },
    getWagerSourceList: () => {
        return get(`${baseUrl}/api/getWagerSourceList`);
    },
    getRiskCategoryList: () => {
        return get(`${baseUrl}/api/getRiskCategoryList`);
    },
    getComboGroupList: () => {
        return get(`${baseUrl}/api/getComboGroupList`);
    },
    getDataMappingByParamCodeForDDL: (data) => {
        return post(`${baseUrl}/api/getDataMappingByParamCodeForDDL`, data);
    },
    getCompetitionListByEventDateRange: (data) => {
        return post(`${baseUrl}/api/getCompetitionListByEventDateRange`, data);
    },
    //#endregion

    //#region Instant Bet
    getActiveCompetitionEventList: (data) => {
        return post(`${baseUrl}/api/getActiveCompetitionEventList`, data);
    },
    //#endregion

    //#region User
    login: (data) => {
        return post(`${baseUrl}/api/login`, data);
    },
    generateEncryptedPassword: (data) => {
        return post(`${baseUrl}/api/generateEncryptedPassword`, data);
    },

    logout: () => {
        return get(`${baseUrl}/api/logout`);
    },

    updatePassword: (data) => {
        return post(`${baseUrl}/api/updatePassword`, data);
    },

    // getUserList: (data) => {
    //     return post(`${baseUrl}/api/getUserList`, data);
    // },

    // createUser: (data) => {
    //     return post(`${baseUrl}/api/createUser`, data);
    // },

    // getUserById: (data) => {
    //     return post(`${baseUrl}/api/getUserById`, data);
    // },

    // updateUserById: (data) => {
    //     return post(`${baseUrl}/api/updateUserById`, data);
    // },

    // getUserStatusList: (data) => {
    //     return get(`${baseUrl}/api/getUserStatusList`, data);
    // },

    resetPasswordByUserId: (data) => {
        return post(`${baseUrl}/api/resetPasswordByUserId`, data);
    },

    // getRoleListForUser: (data) => {
    //     return post(`${baseUrl}/api/getRoleListForUser`, data);
    // },

    generate2FAKey: (data) => {
        return post(`${baseUrl}/api/generate2FAKey`, data);
    },

    verify2FAToken: (data) => {
        return post(`${baseUrl}/api/verify2FAToken`, data);
    },

    enable2FAByUserId: (data) => {
        return post(`${baseUrl}/api/enable2FAByUserId`, data);
    },

    reset2FAByUserId: (data) => {
        return post(`${baseUrl}/api/reset2FAByUserId`, data);
    },
    // getUserAuditLogList: (data) => {
    //     return post(`${baseUrl}/api/getUserAuditLogList`, data);
    // },
    // getUserListingAuditLogFieldTypeDll: () => {
    //     return post(`${baseUrl}/api/getUserListingAuditLogFieldTypeDll`);
    // },
    //#endregion

    //#region Role
    // getRoleStatusList: (data) => {
    //     return get(`${baseUrl}/api/getRoleStatusList`, data);
    // },

    // getRoleList: (data) => {
    //     return post(`${baseUrl}/api/getRoleList`, data);
    // },

    // getRoleWithPermissionById: (data) => {
    //     return post(`${baseUrl}/api/getRoleWithPermissionById`, data);
    // },

    // checkRoleNameExist: (data) => {
    //     return post(`${baseUrl}/api/checkRoleNameExist`, data);
    // },

    // createRole: (data) => {
    //     return post(`${baseUrl}/api/createRole`, data);
    // },

    // updateRoleById: (data) => {
    //     return post(`${baseUrl}/api/updateRoleById`, data);
    // },

    // getPermissionList: (data) => {
    //     return post(`${baseUrl}/api/getPermissionList`, data);
    // },

    getPermissionListByRoleId: (data) => {
        return post(`${baseUrl}/api/getPermissionListByRoleId`, data);
    },
    //#endregion

    //#region ELT Report
    getBucketName: (data) => {
        return get(`${baseUrl}/api/getBucketName`);
    },

    loadCSVToBQ: (data) => {
        return post(`${baseUrl}/api/loadCSVToBQ`, data);
    },

    generateStgData: (data) => {
        return post(`${baseUrl}/api/generateStgData`, data);
    },

    generateDMData: (data) => {
        return post(`${baseUrl}/api/generateDMData`, data);
    },

    exportReportToGCS: (data) => {
        return post(`${baseUrl}/api/exportReportToGCS`, data);
    },

    downloadReportMonthly: (data) => {
        return get(`${baseUrl}/api/getDownloadFileUrl?reportType=${data.reportType}&reportDate=${formatDate(data.startDate, '-')}`);
    },

    downloadReportDaily: (data) => {
        return get(`${baseUrl}/api/getDailyDownloadFileUrl?reportType=${data.reportType}&reportDate=${formatDate(data.startDate, '-')}`);
    },

    downloadReportByDateRange: (data) => {
        return get(
            `${baseUrl}/api/getDateRangeDownloadFileUrl?reportType=${data.reportType}&reportStartDate=${formatDate(data.startDate, '-')}&reportEndDate=${formatDate(
                data.endDate,
                '-',
            )}`,
        );
    },

    downloadReportWeekly: (data) => {
        return get(`${baseUrl}/api/getWeeklyDownloadFileUrl?reportType=${data.reportType}&noOfWeek=${data.noOfWeek}&reportDate=${formatDate(data.startDate, '-')}`);
    },

    generateESWagerFactByJobSchedule: (data) => {
        return post(`${baseUrl}/api/generateESWagerFactByJobSchedule`, data);
    },

    generateESWagerFactByDateMatch: (data) => {
        return post(`${baseUrl}/api/generateESWagerFactByDateMatch`, data);
    },
    //#endregion

    //#region AbnormalBet
    getAbnormalBetPlatformList: () => {
        return get(`${baseUrl}/api/getAbnormalBetPlatformList`);
    },

    getAbnormalBetList: (data) => {
        return post(`${baseUrl}/api/getAbnormalBetList`, data);
    },

    getAbnormalBetListByUpdatedDate: (data) => {
        return post(`${baseUrl}/api/getAbnormalBetListByUpdatedDate`, data);
    },

    getAbnormalBetCriteriaList: (data) => {
        return get(`${baseUrl}/api/getAbnormalBetCriteriaList`, data);
    },

    getAbnormalBetActionStatusList: (data) => {
        return get(`${baseUrl}/api/getAbnormalBetActionStatusList`, data);
    },

    getAbnormalBetWagerTypeList: (data) => {
        return get(`${baseUrl}/api/getAbnormalBetWagerTypeList`, data);
    },

    resendActionById: (data) => {
        return post(`${baseUrl}/api/resendActionById`, data);
    },

    resendActionByBetIdentifier: (data) => {
        return post(`${baseUrl}/api/resendActionByBetIdentifier`, data);
    },

    getAbnormalBetMemberActionList: (data) => {
        return get(`${baseUrl}/api/getAbnormalBetMemberActionList`, data);
    },

    getAbnormalBetWagerActionList: (data) => {
        return get(`${baseUrl}/api/getAbnormalBetWagerActionList`, data);
    },
    //#endregion

    //#region Offline Export
    getOfflineExportList: (data) => {
        return post(`${baseUrl}/api/getOfflineExportList`, data);
    },

    createOfflineExport: (data) => {
        return post(`${baseUrl}/api/createOfflineExport`, data);
    },

    deleteOfflineExport: (data) => {
        return post(`${baseUrl}/api/deleteOfflineExport`, data);
    },

    updateOfflineExport: (data) => {
        return post(`${baseUrl}/api/updateOfflineExport`, data);
    },

    rerunOfflineExportById: (data) => {
        return post(`${baseUrl}/api/rerunOfflineExportById`, data);
    },
    //#endregion

    //#region  FAIP manual release
    setManualReleaseFAIPbyIpAddress: (data) => {
        return post(`${baseUrl}/api/setFaipManualReleaseByIpAddress`, data);
    },

    //#region FAIPWhitelist
    getFAIPWhitelistStatusList: (data) => {
        return get(`${baseUrl}/api/getFAIPWhitelistStatusList`, data);
    },

    getFAIPWhitelistList: (data) => {
        return post(`${baseUrl}/api/getFAIPWhitelistList`, data);
    },

    getFAIPWhitelistById: (data) => {
        return post(`${baseUrl}/api/getFAIPWhitelistById`, data);
    },

    // createFAIPWhitelist: (data) => {
    //     return post(`${baseUrl}/api/createFAIPWhitelist`, data);
    // },

    // updateFAIPWhitelistById: (data) => {
    //     return post(`${baseUrl}/api/updateFAIPWhitelistById`, data);
    // },

    // checkWhitelistIPAddressExist: (data) => {
    //     return post(`${baseUrl}/api/checkWhitelistIPAddressExist`, data);
    // },

    // createFAIPWhiteListByIPAddress: (data) => {
    //     return post(`${baseUrl}/api/createFAIPWhiteListByIPAddress`, data);
    // },

    // createFAIPWhiteListByFAIPId: (data) => {
    //     return post(`${baseUrl}/api/createFAIPWhiteListByFAIPId`, data);
    // },
    //#endregion

    //#region FAIP
    getFAIPPlatformList: () => {
        return get(`${baseUrl}/api/getFAIPPlatformList`);
    },

    getFAIPCriteriaList: () => {
        return get(`${baseUrl}/api/getFAIPCriteriaList`);
    },

    getFAIPStatusList: () => {
        return get(`${baseUrl}/api/getFAIPStatusList`);
    },

    getFAIPList: (data) => {
        return post(`${baseUrl}/api/getFAIPList`, data);
    },

    setFAIPManualReleaseByFAIPId: (data) => {
        return post(`${baseUrl}/api/SetFAIPManualReleaseByFAIPId`, data);
    },
    //#endregion

    //#region SystemSetting
    getSystemSettingModuleList: (data) => {
        return post(`${baseUrl}/api/getSystemSettingModuleList`, data);
    },

    getSystemSettingList: (data) => {
        return post(`${baseUrl}/api/getSystemSettingList`, data);
    },

    getSystemSettingById: (data) => {
        return post(`${baseUrl}/api/getSystemSettingById`, data);
    },

    getSystemSettingByKey: (data) => {
        return post(`${baseUrl}/api/getSystemSettingByKey`, data);
    },

    updateSystemSetting: (data) => {
        return post(`${baseUrl}/api/updateSystemSetting`, data);
    },

    // getSystemSettingAuditListByKey: (data) => {
    //     return post(`${baseUrl}/api/getSystemSettingAuditlogs`, data);
    // },

    getFeatureOnOffSettingModuleList: (data) => {
        return post(`${baseUrl}/api/getFeatureOnOffSettingModuleList`, data);
    },
    getFeatureOnOffSettingMasterList: (data) => {
        return post(`${baseUrl}/api/getFeatureOnOffSettingMasterList`, data);
    },

    getFeatureOnOffSettingList: (data) => {
        return post(`${baseUrl}/api/getFeatureOnOffSettingList`, data);
    },

    getFeatureOnOffSettingById: (data) => {
        return post(`${baseUrl}/api/getFeatureOnOffSettingById`, data);
    },

    updateFeatureOnOffSetting: (data) => {
        return post(`${baseUrl}/api/updateFeatureOnOffSetting`, data);
    },

    getFeatureOnOffSettingAuditListByKey: (data) => {
        return post(`${baseUrl}/api/getFeatureOnOffSettingAuditLogs`, data);
    },

    getCompanyFeatureOnOffSettingList: (data) => {
        return post(`${baseUrl}/api/getCompanyFeatureOnOffSettingList`, data);
    },

    getCompanyFeatureOnOffSettingById: (data) => {
        return post(`${baseUrl}/api/getCompanyFeatureOnOffSettingById`, data);
    },

    updateCompanyFeatureOnOffSettingList: (data) => {
        return post(`${baseUrl}/api/updateCompanyFeatureOnOffSettingList`, data);
    },

    getCompanySystemSettingDllList: () => {
        return post(`${baseUrl}/api/getCompanySystemSettingDllList`);
    },
    //#endregion

    //#region AGB
    getAGBPlatformList: () => {
        return get(`${baseUrl}/api/getAGBPlatformList`);
    },

    getAGBCriteriaList: (data) => {
        return get(`${baseUrl}/api/getAGBCriteriaList`, data);
    },

    getAGBWagerTypeList: (data) => {
        return get(`${baseUrl}/api/getAGBWagerTypeList`, data);
    },

    getAGBBetStatusList: (data) => {
        return get(`${baseUrl}/api/getAGBBetStatusList`, data);
    },

    getAGBCompetitionListByDateRange: (data) => {
        return post(`${baseUrl}/api/getAGBCompetitionListByDateRange`, data);
    },

    getAGBEventIdListByDateRange: (data) => {
        return post(`${baseUrl}/api/getAGBEventIdListByDateRange`, data);
    },

    getAGBList: (data) => {
        return post(`${baseUrl}/api/getAGBList`, data);
    },

    getAGBListForExport: (data) => {
        return post(`${baseUrl}/api/getAGBListForExport`, data);
    },

    getAGBForCurrDbByCompanyList: (data) => {
        return post(`${baseUrl}/api/getAGBForCurrDbByCompanyList`, data);
    },

    getAGBForCurrDbByEventList: (data) => {
        return post(`${baseUrl}/api/getAGBForCurrDbByEventList`, data);
    },

    getAGBForHistDbAGBCountByCompanyList: (data) => {
        return post(`${baseUrl}/api/getAGBForHistDbAGBCountByCompanyList`, data);
    },

    getAGBForHistDbByCompetitionList: (data) => {
        return post(`${baseUrl}/api/getAGBForHistDbByCompetitionList`, data);
    },

    getAGBMemberSummaryList: (data) => {
        return post(`${baseUrl}/api/getAGBMemberSummaryList`, data);
    },

    getAGBMemberSummaryListForExport: (data) => {
        return post(`${baseUrl}/api/getAGBMemberSummaryListForExport`, data);
    },

    getAGBMemberSummaryCompetitionList: (data) => {
        return post(`${baseUrl}/api/getAGBMemberSummaryCompetitionList`, data);
    },

    //#endregion

    //#region MBC
    getBQCompanyList: () => {
        return get(`${baseUrl}/api/getBQCompanyList`);
    },

    getMBCDayRangeList: () => {
        return get(`${baseUrl}/api/getMBCDayRangeList`);
    },

    getGoodMemberCategoryByMemberBehaviourChange: () => {
        return get(`${baseUrl}/api/getGoodMemberCategoryByMemberBehaviourChange`);
    },

    getBadMemberCategoryByMemberBehaviourChange: () => {
        return get(`${baseUrl}/api/getBadMemberCategoryByMemberBehaviourChange`);
    },

    getMemberBehaviourChangeList: (data) => {
        return post(`${baseUrl}/api/getMemberBehaviourChangeList`, data);
    },

    getMemberBehaviourChangeListForExport: (data) => {
        return post(`${baseUrl}/api/getMemberBehaviourChangeListForExport`, data);
    },
    //#endregion

    //#region MemberTagging
    getMemberTaggingLatestReportDT: (data) => {
        return get(`${baseUrl}/api/getMemberTaggingLatestReportDT`, data);
    },

    getMemberTagList: () => {
        return get(`${baseUrl}/api/getMemberTagList`);
    },

    getMemberTaggingList: (data) => {
        return post(`${baseUrl}/api/getMemberTaggingList`, data);
    },

    getCurrDbMemberTaggingList: (data) => {
        return post(`${baseUrl}/api/getCurrDbMemberTaggingList`, data);
    },

    getCurrDbMemberTaggingListForExport: (data) => {
        return post(`${baseUrl}/api/getCurrDbMemberTaggingListForExport`, data);
    },

    getCurrDbMemberTaggingListForExportBQ: (data) => {
        return post(`${baseUrl}/api/getCurrDbMemberTaggingListForExportBQ`, data);
    },

    getHistDbMemberTaggingList: (data) => {
        return post(`${baseUrl}/api/getHistDbMemberTaggingList`, data);
    },

    getMemberTaggingMemberActionList: (data) => {
        return get(`${baseUrl}/api/getMemberTaggingMemberActionList`, data);
    },

    sendMemberTaggingActions: (data) => {
        return post(`${baseUrl}/api/sendMemberTaggingActions`, data);
    },

    getMemberTaggingActionSetting: (data) => {
        return get(`${baseUrl}/api/getMemberTaggingActionSetting`, data);
    },

    getMemberProfileGroupListForMemberTagging: (data) => {
        return post(`${baseUrl}/api/getMemberProfileGroupListForMemberTagging`, data);
    },
    //#endregion

    //#region MemberGrouping
    getMemberGroupingMemberCategoryList: () => {
        return get(`${baseUrl}/api/getMemberCategoryByMemberGrouping`);
    },

    getGroupIDListByMemberGroupingDeviceId: () => {
        return get(`${baseUrl}/api/getGroupIDMemberGroupingDeviceId`);
    },

    getMemberGroupingDeviceIdList: (data) => {
        return post(`${baseUrl}/api/getMemberGroupingDeviceIdList`, data);
    },

    getMemberGroupingDeviceIdLinkedMemberList: (data) => {
        return post(`${baseUrl}/api/getMemberGroupingDeviceIdLinkedMemberList`, data);
    },

    getMemberGroupingDeviceIdNarraMemberList: (data) => {
        return post(`${baseUrl}/api/getMemberGroupingDeviceIdNarraMemberList`, data);
    },
    //#endregion

    //#region JobSchedule
    getLatestSuccessJobScheduleByJobType: (data) => {
        return post(`${baseUrl}/api/getLatestSuccessJobScheduleByJobType`, data);
    },

    getDashboardLatestSuccessJobInfoDetail: (data) => {
        return post(`${baseUrl}/api/getDashboardLatestSuccessJobInfoDetail`, data);
    },

    getDBJobInfoDetailByJobTypeId: (data) => {
        return post(`${baseUrl}/api/getDBJobInfoDetailByJobTypeId`, data);
    },

    getJobInfoDetailByJobType: (data) => {
        return post(`${baseUrl}/api/getJobInfoDetailByJobType`, data);
    },

    updateJobInfoDetailByJobType: (data) => {
        return post(`${baseUrl}/api/updateJobInfoDetailByJobType`, data);
    },
    //#endregion

    //#region FAMember
    getFAMemberPlatformList: () => {
        return get(`${baseUrl}/api/getFAMemberPlatformList`);
    },

    getFAMemberCriteriaList: () => {
        return get(`${baseUrl}/api/getFAMemberCriteriaList`);
    },

    getFAMemberStatusList: () => {
        return get(`${baseUrl}/api/getFAMemberStatusList`);
    },

    getFAMemberList: (data) => {
        return post(`${baseUrl}/api/getFAMemberList`, data);
    },

    setFAMemberManualReleaseByFAMemberId: (data) => {
        return post(`${baseUrl}/api/setFAMemberManualReleaseByFAMemberId`, data);
    },

    setFAMemberManualReleaseByMember: (data) => {
        return post(`${baseUrl}/api/setFAMemberManualReleaseByMember`, data);
    },
    //#endregion

    //#region FAMemberWhitelist
    getFAMemberWhitelistStatusList: (data) => {
        return get(`${baseUrl}/api/getFAMemberWhitelistStatusList`, data);
    },

    getFAMemberWhitelistList: (data) => {
        return post(`${baseUrl}/api/getFAMemberWhitelistList`, data);
    },

    getFAMemberWhitelistById: (data) => {
        return post(`${baseUrl}/api/getFAMemberWhitelistById`, data);
    },

    // createFAMemberWhitelist: (data) => {
    //     return post(`${baseUrl}/api/createFAMemberWhitelist`, data);
    // },

    // createFAMemberWhitelistByMember: (data) => {
    //     return post(`${baseUrl}/api/createFAMemberWhitelistByMember`, data);
    // },

    // createFAMemberWhitelistByFAMemberId: (data) => {
    //     return post(`${baseUrl}/api/createFAMemberWhitelistByFAMemberId`, data);
    // },

    // updateFAMemberWhitelistById: (data) => {
    //     return post(`${baseUrl}/api/updateFAMemberWhitelistById`, data);
    // },

    // checkFAMemberWhitelistExists: (data) => {
    //     return post(`${baseUrl}/api/checkFAMemberWhitelistExists`, data);
    // },
    //#endregion

    //#region MemberCategoryWhitelist

    // checkMemberCategoryWhitelistExists: (data) => {
    //     return post(`${baseUrl}/api/checkMemberCategoryWhitelistExists`, data);
    // },

    getMemberCategoryWhitelistStatusList: (data) => {
        return get(`${baseUrl}/api/getMemberCategoryWhitelistStatusList`, data);
    },

    // getMemberCategoryWhitelists: (data) => {
    //     return post(`${baseUrl}/api/getMemberCategoryWhitelists`, data);
    // },

    // getMemberCategoryWhitelistById: (data) => {
    //     return post(`${baseUrl}/api/getMemberCategoryWhitelistById`, data);
    // },

    // getMemberCategoryWhitelistCompanyByMemberCategoryWhitelistID: (data) => {
    //     return post(`${baseUrl}/api/getMemberCategoryWhitelistCompanyByMemberCategoryWhitelistID`, data);
    // },

    // createMemberCategoryWhitelist: (data) => {
    //     return post(`${baseUrl}/api/createMemberCategoryWhitelist`, data);
    // },

    // updateMemberCategoryWhitelistById: (data) => {
    //     return post(`${baseUrl}/api/updateMemberCategoryWhitelistById`, data);
    // },
    //#endregion

    //#region ComboGhost
    getCompetitionGroupList: () => {
        return get(`${baseUrl}/api/getCompetitionGroupList`);
    },

    getFullCoverComboGroupList: () => {
        return get(`${baseUrl}/api/getFullCoverComboGroupList`);
    },

    // getFullCoverList: (data) => {
    //     return post(`${baseUrl}/api/getFullCoverList`, data);
    // },

    // getFullCoverAlertList: (data) => {
    //     return post(`${baseUrl}/api/getFullCoverAlertList`, data);
    // },

    getFullCoverWagerDetailList: (data) => {
        return post(`${baseUrl}/api/getFullCoverWagerDetailList`, data);
    },

    getHighExposureComboGroupList: () => {
        return get(`${baseUrl}/api/getHighExposureComboGroupList`);
    },

    // getHighExposureList: (data) => {
    //     return post(`${baseUrl}/api/getHighExposureList`, data);
    // },

    // getHighExposureAlertList: (data) => {
    //     return post(`${baseUrl}/api/getHighExposureAlertList`, data);
    // },

    getHighExposureWagerDetailList: (data) => {
        return post(`${baseUrl}/api/getHighExposureWagerDetailList`, data);
    },

    getHighExposureMemberListByIP: (data) => {
        return post(`${baseUrl}/api/getHighExposureMemberListByIP`, data);
    },
    //#endregion

    //#region Member Summary
    getMemberSummaryList: (data) => {
        return post(`${baseUrl}/api/getMemberSummaryList`, data);
    },
    getMemberSummaryListExport: (data) => {
        return post(`${baseUrl}/api/getMemberSummaryListForExport`, data);
    },
    getMemberSummaryDetailMemberList: (data) => {
        return post(`${baseUrl}/api/getMemberSummaryDetailMemberList`, data);
    },
    //#endregion

    //#region Profile Group
    checkMemberProfileGroupCompanySettingChanged: (data) => {
        return post(`${baseUrl}/api/checkMemberProfileGroupCompanySettingChanged`, data);
    },
    checkMemberProfileGroupNameExist: (data) => {
        return post(`${baseUrl}/api/checkMemberProfileGroupNameExist`, data);
    },
    getMemberProfileGroupStatusList: (data) => {
        return get(`${baseUrl}/api/getMemberProfileGroupStatusList`, data);
    },
    getMemberProfileGroupModuleList: (data) => {
        return get(`${baseUrl}/api/getMemberProfileGroupModuleList`, data);
    },
    getMemberProfileGroupList: (data) => {
        return post(`${baseUrl}/api/getMemberProfileGroupList`, data);
    },
    getMemberProfileGroupById: (data) => {
        return post(`${baseUrl}/api/getMemberProfileGroupById`, data);
    },
    createMemberProfileGroup: (data) => {
        return post(`${baseUrl}/api/createMemberProfileGroup`, data);
    },
    updateMemberProfileGroupById: (data) => {
        return post(`${baseUrl}/api/updateMemberProfileGroupById`, data);
    },
    getMemberProfileGroupListByStatus: (data) => {
        return post(`${baseUrl}/api/getMemberProfileGroupListByStatus`, data);
    },
    getMemberProfileGroupForDDL: () => {
        return get(`${baseUrl}/api/getMemberProfileGroupForDDL`);
    },
    //#endregion

    //#region Member Action
    getMemberActionStatusList: (data) => {
        return get(`${baseUrl}/api/getMemberActionStatusList`, data);
    },
    getMemberActionList: (data) => {
        return post(`${baseUrl}/api/getMemberActionList`, data);
    },
    //#endregion

    //#region One Sided Event
    getOneSidedEventBetTypeList: (data) => {
        return get(`${baseUrl}/api/getOneSidedEventBetTypeList`, data);
    },
    getOneSidedEventPeriodList: (data) => {
        return get(`${baseUrl}/api/getOneSidedEventPeriodList`, data);
    },
    getOneSidedEventMarketList: (data) => {
        return get(`${baseUrl}/api/getOneSidedEventMarketList`, data);
    },
    getOneSidedWagerTypeList: (data) => {
        return get(`${baseUrl}/api/getOneSidedWagerTypeList`, data);
    },
    getOneSidedPlatformList: (data) => {
        return get(`${baseUrl}/api/getOneSidedPlatformList`, data);
    },
    // getOneSidedEventCompetitionList: (data) => {
    //     return post(`${baseUrl}/api/getOneSidedEventCompetitionList`, data);
    // },
    // getOneSidedEventList: (data) => {
    //     return post(`${baseUrl}/api/getOneSidedEventList`, data);
    // },
    // getOneSidedEventAlertList: (data) => {
    //     return post(`${baseUrl}/api/getOneSidedEventAlertList`, data);
    // },
    getOneSidedEventSetting: () => {
        return post(`${baseUrl}/api/getOneSidedEventSetting`);
    },
    getOneSidedEventWagerDetails: (data) => {
        return post(`${baseUrl}/api/getOneSidedEventWagerDetailList`, data);
    },
    //#endregion

    //#region Member Listing
    getMemberIsOverrideBySystemList: () => {
        return get(`${baseUrl}/api/getMemberIsOverrideBySystemList`);
    },
    getMemberCurrencyList: () => {
        return get(`${baseUrl}/api/getMemberCurrencyList`);
    },
    getMemberListingActionList: () => {
        return get(`${baseUrl}/api/getMemberListingActionList`);
    },
    getMemberListingActionSetting: (data) => {
        return get(`${baseUrl}/api/getMemberListingActionSetting`, data);
    },
    getMemberStatusList: () => {
        return get(`${baseUrl}/api/getMemberStatusList`);
    },
    getMemberProfileSettingChangedByMemberList: (data) => {
        return post(`${baseUrl}/api/getMemberProfileSettingChangedByMemberList`, data);
    },
    getMemberProfileSettingChangedByMemberListWithUIData: (data) => {
        return post(`${baseUrl}/api/getMemberProfileSettingChangedByMemberListWithUIData`, data);
    },
    getMemberList: (data) => {
        return post(`${baseUrl}/api/getMemberList`, data);
    },
    getMemberSettingByMemberID: (data) => {
        return post(`${baseUrl}/api/getMemberSettingByMemberID`, data);
    },
    getMemberSettingList: (data) => {
        return post(`${baseUrl}/api/getMemberSettingList`, data);
    },
    getMemberRemarkList: (data) => {
        return post(`${baseUrl}/api/getMemberRemarkList`, data);
    },
    createMemberRemark: (data) => {
        return post(`${baseUrl}/api/createMemberRemark`, data);
    },
    updateMemberRemarkById: (data) => {
        return post(`${baseUrl}/api/updateMemberRemarkById`, data);
    },
    getMemberAuditLogFieldTypeList: (data) => {
        return post(`${baseUrl}/api/getMemberAuditLogFieldTypeList`, data);
    },
    getMemberAuditlogs: (data) => {
        return post(`${baseUrl}/api/getMemberAuditlogs`, data);
    },
    updateMemberById: (data) => {
        return post(`${baseUrl}/api/updateMemberById`, data);
    },
    sendMemberListingActions: (data) => {
        return post(`${baseUrl}/api/processMemberAction`, data);
    },
    // updateMemberProfileSettingChangedByMemberList: (data) => {
    //     return post(`${baseUrl}/api/updateMemberProfileSettingChangedByMemberList`, data);
    // },
    getGBFeatureListByMemberListing: () => {
        return get(`${baseUrl}/api/getGBFeatureListByMemberListing`);
    },
    getSortByListByMemberListing: () => {
        return get(`${baseUrl}/api/getSortByListByMemberListing`);
    },
    //#endregion

    //Search Criteria
    getSearchCriterias: (data) => {
        return post(`${baseUrl}/api/getSearchCriterias`, data);
    },
    saveSearchCriterias: (data) => {
        return post(`${baseUrl}/api/saveSearchCriterias`, data);
    },

    //region MemberGrouping (Members Cookie)
    getGroupIDListByMembersCookie: () => {
        return get(`${baseUrl}/api/getGroupIDListByMembersCookie`);
    },

    getMemberCookieGroupingList: (data) => {
        return post(`${baseUrl}/api/getMemberCookieGroupingList`, data);
    },
    getMemberCookieGroupingListByID: (data) => {
        return post(`${baseUrl}/api/getMemberCookieGroupingListByID`, data);
    },
    //#endregion

    //#region Event Dashboard
    getEventDashboardEventStatusByID: (data) => {
        return post(`${baseUrl}/api/getEventDashboardEventStatusByID`, data);
    },
    getEventDashboardHandicapList: (data) => {
        return post(`${baseUrl}/api/getEventDashboardHandicapList`, data);
    },
    getEventDashboardForChart: (data) => {
        return post(`${baseUrl}/api/getEventDashboardForChart`, data);
    },
    getEventDashboardByOddsLogList: (data) => {
        return post(`${baseUrl}/api/getEventDashboardByOddsLogList`, data);
    },
    getEventDashboardByMemberCategoryList: (data) => {
        return post(`${baseUrl}/api/getEventDashboardByMemberCatergoryList`, data);
    },
    //#endregion

    // #region FA Member Blacklist
    getFAMemberBlacklistStatusList: () => {
        return get(`${baseUrl}/api/getFAMemberBlacklistStatusList`);
    },
    getFAMemberBlacklistList: (data) => {
        return post(`${baseUrl}/api/getFAMemberBlacklistList`, data);
    },
    getFAMemberBlacklistById: (data) => {
        return post(`${baseUrl}/api/getFAMemberBlacklistById`, data);
    },
    // checkFAMemberBlacklistExists: (data) => {
    //     return post(`${baseUrl}/api/checkFAMemberBlacklistExists`, data);
    // },
    // createFAMemberBlacklist: (data) => {
    //     return post(`${baseUrl}/api/createFAMemberBlacklist`, data);
    // },
    // updateFAMemberBlacklistById: (data) => {
    //     return post(`${baseUrl}/api/updateFAMemberBlacklistById`, data);
    // },
    //#endregion

    // #region Data Mapping
    getDataMappingModuleList: () => {
        return get(`${baseUrl}/api/getDataMappingModuleList`);
    },
    getParameterCodeListByModuleId: (data) => {
        return post(`${baseUrl}/api/getParameterCodeListByModuleId`, data);
    },
    getDataMappingSportList: () => {
        return get(`${baseUrl}/api/getDataMappingSportList`);
    },
    getDataMappingStatusList: () => {
        return get(`${baseUrl}/api/getDataMappingStatusList`);
    },
    getDataMappingList: (data) => {
        return post(`${baseUrl}/api/getDataMappingList`, data);
    },
    getDataMappingById: (data) => {
        return post(`${baseUrl}/api/getDataMappingById`, data);
    },
    // updateDataMappingById: (data) => {
    //     return post(`${baseUrl}/api/updateDataMappingById`, data);
    // },
    // createDataMappingById: (data) => {
    //     return post(`${baseUrl}/api/createDataMappingById`, data);
    // },
    getDataMappingAuditListByKey: (data) => {
        return post(`${baseUrl}/api/getDataMappingAuditListByKey`, data);
    },
    getDataMappingCompanyGroupListForDDL: () => {
        return get(`${baseUrl}/api/getDataMappingCompanyGroupListForDDL`);
    },
    getDataMappingCompanyGroupList: (data) => {
        return post(`${baseUrl}/api/getDataMappingCompanyGroupList`, data);
    },
    getDataMappingCompanyGroupById: (data) => {
        return post(`${baseUrl}/api/getDataMappingCompanyGroupById`, data);
    },
    // #endregion

    // #region Risk Summary
    getRiskSummaryWagerDimensionList: () => {
        return get(`${baseUrl}/api/getRiskSummaryWagerDimensionList`);
    },
    getRiskSummaryBetTypeListByDateRange: (data) => {
        return post(`${baseUrl}/api/getRiskSummaryBetTypeListByDateRange`, data);
    },
    getRiskSummaryMemberCategoryList: () => {
        return get(`${baseUrl}/api/getRiskSummaryMemberCategoryList`);
    },
    getRiskSummaryPlatformList: () => {
        return get(`${baseUrl}/api/getRiskSummaryPlatformList`);
    },
    getRiskSummaryPeriodList: () => {
        return get(`${baseUrl}/api/getRiskSummaryPeriodList`);
    },
    getRiskSummaryMarketList: () => {
        return get(`${baseUrl}/api/getRiskSummaryMarketList`);
    },
    getRiskSummaryBetTypeList: () => {
        return get(`${baseUrl}/api/getRiskSummaryBetTypeList`);
    },
    getRiskSummaryIsAdvisedList: () => {
        return get(`${baseUrl}/api/getRiskSummaryIsAdvisedList`);
    },
    getRiskSummaryTopDropdownList: () => {
        return get(`${baseUrl}/api/getRiskSummaryTopDropdownList`);
    },
    getRiskSummaryWinnerTopDropdownList: () => {
        return get(`${baseUrl}/api/getRiskSummaryWinnerTopDropdownList`);
    },
    getRiskSummaryMerchantDropdownList: () => {
        return get(`${baseUrl}/api/getRiskSummaryMerchantDropdownList`);
    },
    getRiskSummaryLeagueDropdownList: () => {
        return get(`${baseUrl}/api/getRiskSummaryLeagueDropdownList`);
    },
    getWagerComboSelectionList: (data) => {
        return get(`${baseUrl}/api/getWagerComboSelectionList`);
    },
    getBetOnList: (data) => {
        return get(`${baseUrl}/api/getBetOnList`);
    },
    getLiveWagerSourceList: (data) => {
        return get(`${baseUrl}/api/getLiveWagerSourceList`);
    },
    getRiskSummaryCancelReasonList: (data) => {
        return get(`${baseUrl}/api/getRiskSummaryCancelReasonList`);
    },
    getRiskSummaryTopPerformanceList: (data) => {
        return post(`${baseUrl}/api/getRiskSummaryTopPerformanceList`, data);
    },
    getRiskSummaryTopPerformanceWagerBetDetail: (data) => {
        return post(`${baseUrl}/api/getRiskSummaryTopPerformanceWagerBetDetail`, data);
    },
    getRiskSummarySettledWagerList: (data) => {
        return post(`${baseUrl}/api/getRiskSummarySettledWagerList`, data);
    },
    getRiskSummaryCancelledWagerList: (data) => {
        return post(`${baseUrl}/api/getRiskSummaryCancelledWagerList`, data);
    },
    // #endregion

    // #region Event Settlement
    getEventSettlementTaggingBreakdownTypeList: () => {
        return get(`${baseUrl}/api/getEventSettlementTaggingBreakdownTypeList`);
    },
    getEventSettlementTaggingPeriodList: () => {
        return get(`${baseUrl}/api/getEventSettlementTaggingPeriodList`);
    },
    getEventSettlementTaggingTypeList: () => {
        return get(`${baseUrl}/api/getEventSettlementTaggingTypeList`);
    },
    getEventSettlementTaggingStatusList: () => {
        return get(`${baseUrl}/api/getEventSettlementTaggingStatusList`);
    },
    getEventSettlementTaggingBySportList: (data) => {
        return post(`${baseUrl}/api/getEventSettlementTaggingBySportList`, data);
    },
    getEventSettlementTaggingByCompetitionList: (data) => {
        return post(`${baseUrl}/api/getEventSettlementTaggingByCompetitionList`, data);
    },
    getEventSettlementTaggingByEventList: (data) => {
        return post(`${baseUrl}/api/getEventSettlementTaggingByEventList`, data);
    },
    // #endregion

    // #region Theoretical Winloss Margin
    getTheoreticalWinlossMarginList: (data) => {
        return post(`${baseUrl}/api/getTheoreticalWinlossMarginList`, data);
    },
    getTheoreticalWLMarginBetTypeList: () => {
        return get(`${baseUrl}/api/getTheoreticalWLMarginBetTypeList`);
    },
    getTheoreticalWLMarginPeriodList: () => {
        return get(`${baseUrl}/api/getTheoreticalWLMarginPeriodList`);
    },
    // #endregion

    // #region Event Uptime Monitoring
    getEventUptimeMonitoringList: (data) => {
        return post(`${baseUrl}/api/getEventUptimeMonitoringList`, data);
    },
    getEventUptimeMonitoringSortByList: () => {
        return get(`${baseUrl}/api/getEventUptimeMonitoringSortByList`);
    },
    // #endregion

    // #region Internal Audit
    getInternalAuditStatusList: () => {
        return get(`${baseUrl}/api/getInternalAuditStatusList`);
    },
    getInternalAuditActionLogList: () => {
        return get(`${baseUrl}/api/getInternalAuditActionLogList`);
    },
    getInternalAuditWagerCancelReasonTypeList: () => {
        return get(`${baseUrl}/api/getInternalAuditWagerCancelReasonTypeList`);
    },
    getInternalAuditWagerUnCancelReasonTypeList: () => {
        return get(`${baseUrl}/api/getInternalAuditWagerUnCancelReasonTypeList`);
    },
    getSFUserRolesList: () => {
        return get(`${baseUrl}/api/getSFUserRolesList`);
    },
    getInternalAuditEventResultLogListByUser: (data) => {
        return post(`${baseUrl}/api/getInternalAuditEventResultLogListByUser`, data);
    },
    getInternalAuditEventResultLogListByWager: (data) => {
        return post(`${baseUrl}/api/getInternalAuditEventResultLogListByWager`, data);
    },
    getInternalAuditEventResultLogListByMonth: (data) => {
        return post(`${baseUrl}/api/getInternalAuditEventResultLogListByMonth`, data);
    },
    getInternalAuditEventResultLogListByDay: (data) => {
        return post(`${baseUrl}/api/getInternalAuditEventResultLogListByDay`, data);
    },
    // #endregion

    //#region Member Performance
    getMemberPerformancePlatformList: (data) => {
        return get(`${baseUrl}/api/getMemberPerformancePlatformList`, data);
    },
    getMemberPerformanceCompanyList: (data) => {
        return post(`${baseUrl}/api/getMemberPerformanceCompanyList`, data);
    },
    getMemberPerformanceMemberCategoryList: (data) => {
        return post(`${baseUrl}/api/getMemberPerformanceMemberCategoryList`, data);
    },
    getMemberPerformanceMemberCategoryListForExport: (data) => {
        return post(`${baseUrl}/api/getMemberPerformanceMemberCategoryListForExport`, data);
    },
    getMemberPerformanceMemberDetailList: (data) => {
        return post(`${baseUrl}/api/getMemberPerformanceMemberDetailList`, data);
    },
    //#endregion
};
export default api;
