exports.randomString = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

exports.checkIsNullOrEmpty = (str) => {
    if (str !== undefined && str !== null && str !== '') return false;

    return true;
};

exports.checkIsNullOrEmptyOrWhiteSpace = (str) => {
    if (str !== undefined && str !== null && str !== '' && str.trim().length !== 0) return false;

    return true;
};

exports.formatEmptyToNull = (str) => {
    if (this.checkIsNullOrEmptyOrWhiteSpace(str)) {
        return null;
    } else return str;
};

exports.formatNullDisplayStr = (str, nullStr = 'N/A') => {
    if (str !== undefined && str !== null && str !== '') return str;

    return nullStr;
};

exports.formatNullDisplayStrWithDash = (str, nullStr = '-') => {
    if (str !== undefined && str !== null && str !== '') return str;

    return nullStr;
};

exports.formatNullOrZeroDisplayStr = (str, nullStr = '-') => {
    if (str !== undefined && str !== null && str !== '' && str !== 0 && parseInt(str) !== 0) return str;

    return nullStr;
};

exports.formatNullDisplayJoinStr = (strArr, separator, nullStr = 'N/A') => {
    let text = strArr.join(separator);

    if (text !== undefined && text !== null && text !== '' && text !== separator) return text;

    return nullStr;
};

exports.RandomString = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

exports.ReplaceLastWildcard = (str) => {
    if (str.endsWith('*')) {
        return str.slice(0, -1) + '%';
    }
    return str;
};

exports.FormatString = (template, ...values) => {
    return template.replace(/{(\d+)}/g, (match, index) => {
        return typeof values[index] !== 'undefined' ? values[index] : match;
    });
};

exports.DynamicFormatString = (template, params = {}) => {
    return template.replace(/{(\w+)}/g, (match, key) => {
        return key in params ? params[key] : match;
    });
};

exports.FormatMinutesToDaysHoursMinutes = (totalMinutes) => {
    let days = Math.floor(totalMinutes / (60 * 24));
    let hours = Math.floor((totalMinutes % (60 * 24)) / 60);
    let minutes = totalMinutes % 60;

    let result = [];
    if (days > 0) result.push(`${days} days`);
    if (hours > 0) result.push(`${hours} hrs`);
    if (minutes > 0) result.push(`${minutes} mins`);

    return result.join(' ');
};

exports.formatTimespanToHHMMSS = (timespanStr) => {
    let totalHours, minutes, seconds;

    // Check if timespan contains a day part (indicated by a dot '.')
    if (timespanStr.includes('.')) {
        const [days, time] = timespanStr.split('.');
        const [hours, mins, secs] = time.split(':');

        // Convert the days into hours and sum with the hours part
        totalHours = parseInt(days, 10) * 24 + parseInt(hours, 10);
        minutes = mins;
        seconds = secs;
    } else {
        // If no day part, treat it as HH:MM:SS
        const [hours, mins, secs] = timespanStr.split(':');
        totalHours = parseInt(hours, 10);
        minutes = mins;
        seconds = secs;
    }

    // Ensure hours, minutes, and seconds are always two digits
    const formattedHours = String(totalHours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};
