import { get, post, postFormData } from '../utils/fetch';

const apiGBDashboardUrl = process.env.REACT_APP_API_GB_DASHBOARD;
console.log(`apiGBDashboard: ${apiGBDashboardUrl}`);

const apiGBDashboard = {
    //#region Common
    getSportList: () => {
        return get(`${apiGBDashboardUrl}/api/common/getSportList`);
    },
    getCompanyList: () => {
        return get(`${apiGBDashboardUrl}/api/common/getCompanyList`);
    },
    getMemberCategoryList: () => {
        return get(`${apiGBDashboardUrl}/api/common/getMemberCategoryList`);
    },
    getMemberSettingProfileList: () => {
        return get(`${apiGBDashboardUrl}/api/common/getMemberSettingProfileList`);
    },
    getMemberSpreadGroupList: () => {
        return get(`${apiGBDashboardUrl}/api/common/getMemberSpreadGroupList`);
    },
    getMemberIsAdvisedList: () => {
        return get(`${apiGBDashboardUrl}/api/common/getMemberIsAdvisedList`);
    },
    getMemberStatusList: () => {
        return get(`${apiGBDashboardUrl}/api/common/getMemberStatusList`);
    },
    getMemberIsHousePlayerList: () => {
        return get(`${apiGBDashboardUrl}/api/common/getMemberIsHousePlayerList`);
    },
    getPlatformList: () => {
        return get(`${apiGBDashboardUrl}/api/common/getPlatformList`);
    },
    getCompanyListWithGroup: (data) => {
        return post(`${apiGBDashboardUrl}/api/common/getcompanylistwithgroup`, data);
    },
    getLatestSuccessJobScheduleByJobType: (data) => {
        return post(`${apiGBDashboardUrl}/api/common/getLatestSuccessJobScheduleByJobType`, data);
    },
    getMemberActionStatusList: () => {
        return get(`${apiGBDashboardUrl}/api/common/getMemberActionStatusList`);
    },
    getNoActionStatusList: () => {
        return get(`${apiGBDashboardUrl}/api/common/getNoActionStatusList`);
    },
    getWagerCancelReasonTypeList: () => {
        return get(`${apiGBDashboardUrl}/api/Common/GetWagerCancelReasonTypeList`);
    },
    getWagerDangerReasonTypeList: () => {
        return get(`${apiGBDashboardUrl}/api/Common/GetWagerDangerReasonTypeList`);
    },
    getWagerDetailList: (data) => {
        return post(`${apiGBDashboardUrl}/api/Common/GetWagerDetailList`, data);
    },
    getWagerSourceList: () => {
        return get(`${apiGBDashboardUrl}/api/Common/GetWagerSourceList`);
    },
    getWagerTypeList: () => {
        return get(`${apiGBDashboardUrl}/api/Common/GetWagerTypeList`);
    },
    getComboGroupList: () => {
        return get(`${apiGBDashboardUrl}/api/Common/GetComboGroupList`);
    },
    getWagerTypeComboSelectionList: () => {
        return get(`${apiGBDashboardUrl}/api/Common/GetWagerTypeComboSelectionList`);
    },
    getWagerComboSelectionList: () => {
        return get(`${apiGBDashboardUrl}/api/Common/GetWagerComboSelectionList`);
    },
    getPeriodCodeList: () => {
        return get(`${apiGBDashboardUrl}/api/Common/GetPeriodCodeList`);
    },
    getMarketList: () => {
        return get(`${apiGBDashboardUrl}/api/Common/GetMarketList`);
    },
    getBetTypeList: () => {
        return get(`${apiGBDashboardUrl}/api/Common/GetBetTypeList`);
    },
    getBetOnList: () => {
        return get(`${apiGBDashboardUrl}/api/Common/GetBetOnList`);
    },
    getBetTypeSelectionList: () => {
        return get(`${apiGBDashboardUrl}/api/Common/GetBetTypeSelectionList`);
    },
    getAuditLogByModuleID: (data) => {
        return post(`${apiGBDashboardUrl}/api/Common/GetAuditLogByModuleID`, data);
    },
    getDetailAuditLogByModuleID: (data) => {
        return post(`${apiGBDashboardUrl}/api/Common/GetDetailAuditLogByModuleID`, data);
    },
    getAuditLogFieldTypeListByModuleID: (data) => {
        return post(`${apiGBDashboardUrl}/api/Common/GetAuditLogFieldTypeListByModuleID`, data);
    },
    getMemberFirstSourceList: () => {
        return get(`${apiGBDashboardUrl}/api/Common/GetMemberFirstSourceList`);
    },
    getCurrencyList: () => {
        return get(`${apiGBDashboardUrl}/api/Common/GetCurrencyList`);
    },
    getMemberIsOverrideBySystemList: () => {
        return get(`${apiGBDashboardUrl}/api/common/GetMemberIsOverrideBySystemList`);
    },
    getCompetitionListByEventDateRange: (data) => {
        return post(`${apiGBDashboardUrl}/api/common/GetCompetitionListByEventDateRange`, data);
    },
    getCompetitionListByWagerCreatedDate: (data) => {
        return post(`${apiGBDashboardUrl}/api/common/GetCompetitionListByWagerCreatedDate`, data);
    },
    getGBRulesListForDDL: (data) => {
        return post(`${apiGBDashboardUrl}/api/common/GetGBRulesListForDDL`, data);
    },
    getGBStatusList: () => {
        return get(`${apiGBDashboardUrl}/api/common/GetGBStatusList`);
    },
    getCompanyTypeList: () => {
        return get(`${apiGBDashboardUrl}/api/Common/GetCompanyTypeList`);
    },
    getRiskCategoryList: () => {
        return get(`${apiGBDashboardUrl}/api/Common/GetRiskCategoryList`);
    },
    getPlaceBetStatusList: () => {
        return get(`${apiGBDashboardUrl}/api/Common/GetPlaceBetStatusList`);
    },
    getCompanyListByMemberCode: (data) => {
        return post(`${apiGBDashboardUrl}/api/common/GetCompanyListByMemberCode`, data);
    },
    getMemberActionStatusByGBOrderForScoreProfileList: () => {
        return get(`${apiGBDashboardUrl}/api/Common/GetMemberActionStatusByGBOrderForScoreProfileList`);
    },
    getMemberActionStatusByGBOrderForProfileGroupList: () => {
        return get(`${apiGBDashboardUrl}/api/Common/GetMemberActionStatusByGBOrderForProfileGroupList`);
    },
    getEventAndCompetitionListFromEMM: (data) => {
        return post(`${apiGBDashboardUrl}/api/common/GetEventAndCompetitionListFromEMM`, data);
    },
    getManualCancelledReasonList: (data) => {
        return get(`${apiGBDashboardUrl}/api/common/GetWagerManualCancelledReasonList`);
    },
    getSystemCancelledReasonList: (data) => {
        return get(`${apiGBDashboardUrl}/api/common/GetWagerSystemCancelledReasonList`);
    },
    getUnCancelledReasonList: (data) => {
        return get(`${apiGBDashboardUrl}/api/common/GetWagerUnCancelledReasonList`);
    },
    getCompetitonGroupList: (data) => {
        return get(`${apiGBDashboardUrl}/api/Common/GetCompetitionGroupList`);
    },
    getMemberIsManualRevisedList: () => {
        return get(`${apiGBDashboardUrl}/api/common/GetMemberIsManualRevisedList`);
    },
    insertUserActivityLog: (data) => {
        return post(`${apiGBDashboardUrl}/api/Common/InsertUserActivityLog`, data);
    },
    //#endregion

    //#region Common > Search Criteria
    SaveSearchCriteriaByModuleByUser: (data) => {
        return post(`${apiGBDashboardUrl}/api/Common/SaveSearchCriteriaByModuleByUser`, data);
    },
    getSearchCriteriaByModuleByUser: (data) => {
        return post(`${apiGBDashboardUrl}/api/Common/GetSearchCriteriaByModuleByUser`, data);
    },
    //#endregion

    //#region Event
    getEventResultPeriodList: () => {
        return get(`${apiGBDashboardUrl}/api/Common/GetEventResultPeriodList`);
    },
    //#endregion

    //#region OfflineExport
    getOfflineExportList: (data) => {
        return post(`${apiGBDashboardUrl}/api/OfflineExport/getOfflineExportList`, data);
    },
    updateOfflineExport: (data) => {
        return post(`${apiGBDashboardUrl}/api/OfflineExport/updateOfflineExport`, data);
    },
    createRunOfflineExport: (data) => {
        return post(`${apiGBDashboardUrl}/api/OfflineExport/createRunOfflineExport`, data);
    },
    rerunOfflineExportById: (data) => {
        return post(`${apiGBDashboardUrl}/api/OfflineExport/rerunOfflineExportById`, data);
    },
    //#endregion

    //#region SystemSetting
    getSystemSettingByID: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/GetSystemSettingByID`, data);
    },
    getSystemSettingListByModule: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/GetSystemSettingListByModule`, data);
    },
    getSystemSettingByKey: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/getSystemSettingByKey`, data);
    },
    reloadSystemSettingAndCondition: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/reloadSystemSettingAndCondition`, data);
    },
    reloadCompanySystemSettingByCompanyIds: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/reloadCompanySystemSettingByCompanyIds`, data);
    },
    getGeneralSettingModuleList: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/GetGeneralSettingModuleList`, data);
    },
    getSystemSettingList: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/getSystemSettingList`, data);
    },
    getGBRuleBandSettingByID: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/getGBRuleBandSettingByID`, data);
    },
    updateSystemSetting: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/updateSystemSetting`, data);
    },
    updateGBRuleBandSettingByID: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/updateGBRuleBandSettingByID`, data);
    },
    getSystemSettingAuditLogsByKey: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/getSystemSettingAuditLogsByKey`, data);
    },
    //#endregion

    //#region Data Mapping
    getDataMappingByParamCodeForDDL: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/getDataMappingByParamCodeForDDL`, data);
    },
    getParameterCodeListByModuleID: (data) => {
        return post(`${apiGBDashboardUrl}/api/SystemSetting/GetParameterCodeListByModuleID`, data);
    },
    getDataMappingList: (data) => {
        return post(`${apiGBDashboardUrl}/api/SystemSetting/GetDataMappingList`, data);
    },
    getDataMappingByID: (data) => {
        return post(`${apiGBDashboardUrl}/api/SystemSetting/GetDataMappingByID`, data);
    },
    updateDataMappingById: (data) => {
        return post(`${apiGBDashboardUrl}/api/SystemSetting/UpdateDataMappingById`, data);
    },
    createDataMappingById: (data) => {
        return post(`${apiGBDashboardUrl}/api/SystemSetting/CreateDataMappingById`, data);
    },
    //#endregion

    //#region Role
    getRoleList: (data) => {
        return post(`${apiGBDashboardUrl}/api/SystemSetting/GetRoleList`, data);
    },
    getRoleWithPermissionByID: (data) => {
        return post(`${apiGBDashboardUrl}/api/SystemSetting/GetRoleWithPermissionByID`, data);
    },
    validateRole: (data) => {
        return post(`${apiGBDashboardUrl}/api/SystemSetting/ValidateRole`, data);
    },
    createRole: (data) => {
        return post(`${apiGBDashboardUrl}/api/SystemSetting/CreateRole`, data);
    },
    updateRoleById: (data) => {
        return post(`${apiGBDashboardUrl}/api/SystemSetting/UpdateRoleById`, data);
    },
    getRoleGroupList: () => {
        return get(`${apiGBDashboardUrl}/api/SystemSetting/GetRoleGroupList`);
    },
    getPermissionList: (data) => {
        return post(`${apiGBDashboardUrl}/api/SystemSetting/GetPermissionList`, data);
    },
    //#endregion

    //#region Wager Report
    getCurrDbWagerReportList: (data) => {
        return post(`${apiGBDashboardUrl}/api/wagerlevelreport/getCurrDbWagerReportList`, data);
    },

    getHistDbWagerReportList: (data) => {
        return post(`${apiGBDashboardUrl}/api/wagerlevelreport/getHistDbWagerReportList`, data);
    },
    //#endregion

    //#region Member Profiling
    getMemberProfilingList: (data) => {
        return post(`${apiGBDashboardUrl}/api/memberlevelreport/getmemberprofilinglist`, data);
    },
    getMemberProfilingListForExport: (data) => {
        return post(`${apiGBDashboardUrl}/api/memberlevelreport/getmemberprofilinglistforexport`, data);
    },
    getMemberProfilingDetailList: (data) => {
        return post(`${apiGBDashboardUrl}/api/memberlevelreport/getmemberprofilingdetaillist`, data);
    },
    GetMemberProfilingMemberCategoryList: (data) => {
        return post(`${apiGBDashboardUrl}/api/memberlevelreport/getmemberprofilingmembercategorylist`, data);
    },
    //#endregion

    //#region Event Settlement
    getEventSettlementTaggingBreakdownTypeList: () => {
        return get(`${apiGBDashboardUrl}/api/eventlevelreport/GetEventSettlementTaggingBreakdownTypeList`);
    },
    getEventSettlementTaggingTypeList: () => {
        return get(`${apiGBDashboardUrl}/api/eventlevelreport/GetEventSettlementTaggingTypeList`);
    },
    getEventSettlementTaggingStatusList: () => {
        return get(`${apiGBDashboardUrl}/api/eventlevelreport/GetEventSettlementTaggingStatusList`);
    },
    getEventSettlementTaggingBySportList: (data) => {
        return post(`${apiGBDashboardUrl}/api/eventlevelreport/GetEventSettlementTaggingBySportList`, data);
    },
    getEventSettlementTaggingByCompetitionList: (data) => {
        return post(`${apiGBDashboardUrl}/api/eventlevelreport/GetEventSettlementTaggingByCompetitionList`, data);
    },
    getEventSettlementTaggingByEventList: (data) => {
        return post(`${apiGBDashboardUrl}/api/eventlevelreport/GetEventSettlementTaggingByEventList`, data);
    },
    //#endregion

    //#region Bet Rejection Member
    getMemberBetRejectionHitPatternList: (data) => {
        return get(`${apiGBDashboardUrl}/api/memberLevelReport/getMemberBetRejectionHitPatternList`);
    },
    getMemberBetRejectionList: (data) => {
        return post(`${apiGBDashboardUrl}/api/memberLevelReport/getMemberBetRejectionList`, data);
    },
    getMemberBetRejectionListForExport: (data) => {
        return post(`${apiGBDashboardUrl}/api/memberLevelReport/getMemberBetRejectionListForExport`, data);
    },
    //#endregion

    //#region JobInfoDetails
    getDBJobInfoDetailByJobTypeId: (data) => {
        return post(`${apiGBDashboardUrl}/api/common/getDBJobInfoDetailByJobTypeId`, data);
    },
    //#endregion

    //#region AGB
    getAGBWagerActionReasonList: () => {
        return get(`${apiGBDashboardUrl}/api/wagerlevelreport/getAGBWagerActionReasonList`);
    },
    getAGBMemberActionReasonList: () => {
        return get(`${apiGBDashboardUrl}/api/wagerlevelreport/getAGBMemberActionReasonList`);
    },
    getAGBBetStatusList: () => {
        return get(`${apiGBDashboardUrl}/api/wagerlevelreport/getAGBBetStatusList`);
    },
    getAGBList: (data) => {
        return post(`${apiGBDashboardUrl}/api/wagerlevelreport/getAGBList`, data);
    },
    getAGBListForExport: (data) => {
        return post(`${apiGBDashboardUrl}/api/wagerlevelreport/getAGBListForExport`, data);
    },
    getAGBCompetitionListByDateRange: (data) => {
        return post(`${apiGBDashboardUrl}/api/wagerlevelreport/getAGBCompetitionListByDateRange`, data);
    },
    //#endregion

    //#AGB Member Summary
    getAGBMemberSummaryList: (data) => {
        return post(`${apiGBDashboardUrl}/api/memberlevelreport/getAGBMemberSummaryList`, data);
    },
    getAGBMemberSummaryCompetitionList: (data) => {
        return post(`${apiGBDashboardUrl}/api/memberlevelreport/getAGBMemberSummaryCompetitionList`, data);
    },
    //#endregion

    //#region Member Category Whitelist
    checkMemberCategoryWhitelistExists: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/CheckMemberCategoryWhitelistExists`, data);
    },
    getMemberCategoryWhitelists: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/GetMemberCategoryWhitelists`, data);
    },

    getMemberCategoryWhitelistById: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/GetMemberCategoryWhitelistById`, data);
    },

    getMemberCategoryWhitelistCompanyByMemberCategoryWhitelistID: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/GetMemberCategoryWhitelistCompanyByMemberCategoryWhitelistID`, data);
    },

    createMemberCategoryWhitelist: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/CreateMemberCategoryWhitelist`, data);
    },

    updateMemberCategoryWhitelistById: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/UpdateMemberCategoryWhitelistById`, data);
    },
    //#endregion

    //#region Member Tagging
    getMemberTaggingActionSetting: (data) => {
        return get(`${apiGBDashboardUrl}/api/memberlevelreport/getMemberTaggingActionSetting`, data);
    },
    getMemberTaggingList: (data) => {
        return post(`${apiGBDashboardUrl}/api/memberlevelreport/GetMemberTaggingList`, data);
    },
    getMemberTagMemberActionStatusList: (data) => {
        return get(`${apiGBDashboardUrl}/api/memberlevelreport/getMemberTagMemberActionStatusList`, data);
    },
    getMemberTagList: (data) => {
        return get(`${apiGBDashboardUrl}/api/memberlevelreport/GetMemberTagList`, data);
    },
    //#endregion

    //#region MemberProfileGroup
    getMemberProfileGroupStatusList: (data) => {
        return get(`${apiGBDashboardUrl}/api/MemberSetting/GetMemberProfileGroupStatusList`, data);
    },
    getMemberProfileGroupModuleList: (data) => {
        return get(`${apiGBDashboardUrl}/api/MemberSetting/GetMemberProfileGroupModuleList`, data);
    },
    getMemberProfileGroupList: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/GetMemberProfileGroupList`, data);
    },
    getMemberProfileGroupByID: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/GetMemberProfileGroupByID`, data);
    },
    createMemberProfileGroup: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/CreateMemberProfileGroup`, data);
    },
    updateMemberProfileGroupByID: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/UpdateMemberProfileGroupByID`, data);
    },
    validateMemberProfileGroup: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/ValidateMemberProfileGroup`, data);
    },
    getMemberProfileGroupForDDL: () => {
        return get(`${apiGBDashboardUrl}/api/MemberSetting/GetMemberProfileGroupForDDL`);
    },
    getActiveMemberProfileGroupForDDL: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/getActiveMemberProfileGroupForDDL`, data);
    },
    getMemberProfileGroupPriorityList: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/GetMemberProfileGroupPriorityList`, data);
    },
    updateMemberProfileGroupPriorityList: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/UpdateMemberProfileGroupPriorityList`, data);
    },
    //#endregion

    //#region Member Listing
    sendMemberListingActions: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/ProcessMemberAction`, data);
    },
    updateMemberProfileSettingChangedByMemberList: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/updateMemberProfileSettingChangedByMemberList`, data);
    },
    getMemberActionList: (data) => {
        return get(`${apiGBDashboardUrl}/api/common/getMemberActionList`, data);
    },
    getMemberSettingByMemberID: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/GetMemberSettingByMemberID`, data);
    },
    getMemberSettingList: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/GetMemberSettingList`, data);
    },
    updateMemberByID: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/UpdateMemberByID`, data);
    },
    getMemberList: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/GetMemberList`, data);
    },
    getMemberListingSortByList: () => {
        return get(`${apiGBDashboardUrl}/api/MemberSetting/GetMemberListingSortByList`);
    },
    getMemberListingMemberActionStatusList: () => {
        return get(`${apiGBDashboardUrl}/api/MemberSetting/GetMemberListingMemberActionStatusList`);
    },
    getMemberListingAdvFilterSelectionList: () => {
        return get(`${apiGBDashboardUrl}/api/MemberSetting/GetMemberListingAdvFilterSelectionList`);
    },
    getMemberAuditlogs: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/GetMemberAuditlogs`, data);
    },
    getMemberAuditLogFieldTypeList: () => {
        return get(`${apiGBDashboardUrl}/api/MemberSetting/GetMemberAuditLogFieldTypeList`);
    },
    //#region member listing - mass change
    massChangeMemberList: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/MassChangeMemberList`, data);
    },
    getMemberActionByGBOrderList: (data) => {
        return get(`${apiGBDashboardUrl}/api/common/GetMemberActionByGBOrderList`, data);
    },
    createMemberRemark: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/CreateMemberRemark`, data);
    },
    updateMemberRemarkByID: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/UpdateMemberRemark`, data);
    },
    getMemberProfileSettingChangedByMemberList: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/GetMemberProfileSettingChangedByMemberList`, data);
    },
    getMemberProfileSettingChangedByMemberListWithUIData: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/GetMemberProfileSettingChangedByMemberListWithUIData`, data);
    },
    //#endregion
    //#endregion

    // #region Member Mass Update
    uploadMemberMassUpdate: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/UploadMemberMassUpdate`, data);
    },
    getMemberMassUpdateStatus: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/GetMemberMassUpdateStatus`, data);
    },
    getMemberMassUpdateTemplate: (data) => {
        return get(`${apiGBDashboardUrl}/api/MemberSetting/GetMemberMassUpdateTemplate`, data);
    },
    validateMemberMassUpdateIsUploading: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/ValidateMemberMassUpdateIsUploading`, data);
    },
    saveMemberMassUpdateList: (data) => {
        return postFormData(`${apiGBDashboardUrl}/api/MemberSetting/SaveMemberMassUpdateList`, data);
    },
    getFileUploadMemberLogByFileId: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/GetFileUploadMemberLogByFileID`, data);
    },
    clearSessionForUser: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/ClearSessionForUser`, data);
    },
    // #endregion

    //#region Member Dashboard
    getGBFeaturesDayRangeList: (data) => {
        return get(`${apiGBDashboardUrl}/api/Common/GetGBFeaturesDayRangeList`, data);
    },
    getDashboardSystemRejectionDetailList: (data) => {
        return post(`${apiGBDashboardUrl}/api/membersetting/getDashboardSystemRejectionDetailList`, data);
    },
    getDashboardGBFeatureSummaryList: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/GetDashboardGBFeatureSummaryList`, data);
    },
    getDashboardDailyPerformanceList: (data) => {
        return post(`${apiGBDashboardUrl}/api/membersetting/getDashboardDailyPerformanceList`, data);
    },
    getDashboardCompetitionPerformanceList: (data) => {
        return post(`${apiGBDashboardUrl}/api/membersetting/getDashboardCompetitionPerformanceList`, data);
    },
    getDashboardIPAddressList: (data) => {
        return post(`${apiGBDashboardUrl}/api/membersetting/getDashboardIPAddressList`, data);
    },
    getDashboardMemberRemarkList: (data) => {
        return post(`${apiGBDashboardUrl}/api/membersetting/GetDashboardMemberRemarkList`, data);
    },
    getDashboardSystemRejectionPerformanceList: (data) => {
        return post(`${apiGBDashboardUrl}/api/membersetting/GetDashboardSystemRejectionPerformanceList`, data);
    },
    getDashboardCancelledWagerPerformanceList: (data) => {
        return post(`${apiGBDashboardUrl}/api/membersetting/GetDashboardCancelledWagerPerformanceList`, data);
    },
    getDashboardWagerPerformanceList: (data) => {
        return post(`${apiGBDashboardUrl}/api/membersetting/GetDashboardWagerPerformanceList`, data);
    },
    getMemberDashboardWagerSourceList: () => {
        return get(`${apiGBDashboardUrl}/api/membersetting/GetMemberDashboardWagerSourceList`);
    },
    getDashboardMemberScore: (data) => {
        return post(`${apiGBDashboardUrl}/api/membersetting/GetDashboardMemberScore`, data);
    },
    getDashboardOverallPerformanceList: (data) => {
        return post(`${apiGBDashboardUrl}/api/membersetting/getDashboardOverallPerformanceList`, data);
    },
    getDashboardBetTogetherList: (data) => {
        return post(`${apiGBDashboardUrl}/api/membersetting/GetDashboardBetTogetherList`, data);
    },
    getDashboardBetTogetherDetailList: (data) => {
        return post(`${apiGBDashboardUrl}/api/membersetting/GetDashboardBetTogetherDetailList`, data);
    },
    // #endregion

    //#region Score Profile
    getScoreTypeListForDDL: () => {
        return get(`${apiGBDashboardUrl}/api/MemberSetting/GetScoreTypeListForDDL`);
    },
    createScoreProfile: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/CreateScoreProfile`, data);
    },
    getScoreProfileList: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/GetScoreProfileList`, data);
    },
    getScoreProfileByID: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/GetScoreProfileByID`, data);
    },
    validateScoreProfile: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/ValidateScoreProfile`, data);
    },
    updateScoreProfileByID: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/UpdateScoreProfileByID`, data);
    },
    getScoreProfileBandsByRuleID: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/GetScoreProfileBandsByRuleID`, data);
    },
    //#endregion

    //#region FAMember
    getFAMemberCriteriaList: (data) => {
        return get(`${apiGBDashboardUrl}/api/memberlevelreport/getFAMemberCriteriaList`, data);
    },
    getFAMemberStatusList: (data) => {
        return get(`${apiGBDashboardUrl}/api/memberlevelreport/getFAMemberStatusList`, data);
    },
    getFAMemberPlatformList: (data) => {
        return get(`${apiGBDashboardUrl}/api/memberlevelreport/getFAMemberPlatformList`, data);
    },
    getFAMemberList: (data) => {
        return post(`${apiGBDashboardUrl}/api/memberlevelreport/GetFAMemberList`, data);
    },
    createFAMemberWhitelistByMember: (data) => {
        return post(`${apiGBDashboardUrl}/api/SystemSetting/CreateFAMemberWhitelistByMember`, data);
    },
    createFAMemberWhitelistByFAMemberId: (data) => {
        return post(`${apiGBDashboardUrl}/api/SystemSetting/CreateFAMemberWhitelistByFAMemberId`, data);
    },
    setFAMemberManualReleaseByFAMemberId: (data) => {
        return post(`${apiGBDashboardUrl}/api/memberlevelreport/SetFAMemberManualReleaseByFAMemberId`, data);
    },
    createFAMemberWhitelist: (data) => {
        return post(`${apiGBDashboardUrl}/api/SystemSetting/CreateFAMemberWhitelist`, data);
    },
    updateFAMemberWhitelistById: (data) => {
        return post(`${apiGBDashboardUrl}/api/SystemSetting/UpdateFAMemberWhitelistById`, data);
    },
    checkFAMemberWhitelistExists: (data) => {
        return post(`${apiGBDashboardUrl}/api/SystemSetting/CheckFAMemberWhitelistExists`, data);
    },
    //#endregion

    //#region TWM
    getTheoreticalWinlossMarginList: (data) => {
        return post(`${apiGBDashboardUrl}/api/memberlevelreport/GetTheoreticalWinlossMarginList`, data);
    },
    getPMTierList: (data) => {
        return get(`${apiGBDashboardUrl}/api/MemberLevelReport/GetPMTierList`);
    },
    //#endregion

    //#region AbnormalBet
    getAbnormalBetList: (data) => {
        return post(`${apiGBDashboardUrl}/api/memberlevelreport/GetAbnormalBetList`, data);
    },
    //#endregion

    //#region FAIP
    getFAIPStatusList: (data) => {
        return get(`${apiGBDashboardUrl}/api/memberlevelreport/getFAIPStatusList`, data);
    },
    getFAIPPlatformList: (data) => {
        return get(`${apiGBDashboardUrl}/api/memberlevelreport/getFAIPPlatformList`, data);
    },
    getFAIPCriteriaList: (data) => {
        return get(`${apiGBDashboardUrl}/api/memberlevelreport/getFAIPCriteriaList`, data);
    },
    getFAIPList: (data) => {
        return post(`${apiGBDashboardUrl}/api/memberlevelreport/getFAIPList`, data);
    },
    setFAIPManualReleaseByFAIPId: (data) => {
        return post(`${apiGBDashboardUrl}/api/memberlevelreport/setFAIPManualReleaseByFAIPId`, data);
    },
    createFAIPWhiteListByFAIPId: (data) => {
        return post(`${apiGBDashboardUrl}/api/memberlevelreport/createFAIPWhiteListByFAIPId`, data);
    },
    createFAIPWhitelist: (data) => {
        return post(`${apiGBDashboardUrl}/api/SystemSetting/CreateFAIPWhitelist`, data);
    },
    createFAIPWhiteListByIPAddress: (data) => {
        return post(`${apiGBDashboardUrl}/api/SystemSetting/CreateFAIPWhiteListByIPAddress`, data);
    },
    updateFAIPWhitelistById: (data) => {
        return post(`${apiGBDashboardUrl}/api/SystemSetting/UpdateFAIPWhitelistById`, data);
    },
    checkWhitelistIPAddressExist: (data) => {
        return post(`${apiGBDashboardUrl}/api/SystemSetting/CheckFAIPWhitelistExists`, data);
    },
    //#endregion

    //#region Support
    getBQCosting: (data) => {
        return post(`${apiGBDashboardUrl}/api/support/GetBQCosting`, data);
    },
    getBQCostingDetail: (data) => {
        return post(`${apiGBDashboardUrl}/api/support/GetBQCostingDetail`, data);
    },
    addNewModuleBySPName: (data) => {
        return post(`${apiGBDashboardUrl}/api/support/addNewModuleBySPName`, data);
    },
    //#endregion

    //#region Daily ELT
    updateJobScheduleByID: (data) => {
        return post(`${apiGBDashboardUrl}/api/common/UpdateJobScheduleByID`, data);
    },
    updateJobInfoDetailByJobType: (data) => {
        return post(`${apiGBDashboardUrl}/api/common/UpdateJobInfoDetailByJobType`, data);
    },
    getJobScheduleStatusList: () => {
        return get(`${apiGBDashboardUrl}/api/support/GetJobScheduleStatusList`);
    },
    getMaloftELTList: (data) => {
        return post(`${apiGBDashboardUrl}/api/Support/GetMaloftELTList`, data);
    },
    getDataExtactionList: (data) => {
        return post(`${apiGBDashboardUrl}/api/Support/GetDataExtactionList`, data);
    },
    resendDataExtractionJob: (data) => {
        return post(`${apiGBDashboardUrl}/api/Support/ResendDataExtractionJob`, data);
    },
    rerunSingleMaloftELTFile: (data) => {
        return post(`${apiGBDashboardUrl}/api/Support/RerunSingleMaloftELTFile`, data);
    },
    getELTResultByDate: (data) => {
        return post(`${apiGBDashboardUrl}/api/Support/GetELTResultByDate`, data);
    },
    //#endregion

    //#region Risk Management
    getRiskSummarySelectionSizeList: () => {
        return get(`${apiGBDashboardUrl}/api/riskmanagement/GetRiskSummarySelectionSizeList`);
    },
    getRiskSummaryWinnerSelectionSizeList: () => {
        return get(`${apiGBDashboardUrl}/api/riskmanagement/GetRiskSummaryWinnerSelectionSizeList`);
    },
    getRiskSummaryMerchantSortByList: () => {
        return get(`${apiGBDashboardUrl}/api/riskmanagement/GetRiskSummaryMerchantSortByList`);
    },
    getRiskSummaryComboSortByList: () => {
        return get(`${apiGBDashboardUrl}/api/riskmanagement/GetRiskSummaryComboSortByList`);
    },
    getRiskSummaryWagerDimensionList: () => {
        return get(`${apiGBDashboardUrl}/api/riskmanagement/GetRiskSummaryWagerDimensionList`);
    },
    getRiskSummaryTopPerformanceList: (data) => {
        return post(`${apiGBDashboardUrl}/api/riskmanagement/GetRiskSummaryTopPerformanceList`, data);
    },
    getRiskSummarySettledWagerList: (data) => {
        return post(`${apiGBDashboardUrl}/api/riskmanagement/GetRiskSummarySettledWagerList`, data);
    },
    getRiskSummaryCancelledWagerList: (data) => {
        return post(`${apiGBDashboardUrl}/api/riskmanagement/GetRiskSummaryCancelledWagerList`, data);
    },
    getBetAttemptListByEvent: (data) => {
        return post(`${apiGBDashboardUrl}/api/riskmanagement/GetBetAttemptListByEvent`, data);
    },
    getBetAttemptListByMember: (data) => {
        return post(`${apiGBDashboardUrl}/api/riskmanagement/GetBetAttemptListByMember`, data);
    },
    //#endregion

    //#region ArberMemberSummary
    getArberMemberSummaryList: (data) => {
        return post(`${apiGBDashboardUrl}/api/memberlevelreport/GetArberMemberSummaryList`, data);
    },
    //#endregion

    //#region Generate Report
    loadCSVToBQ: (data) => {
        return post(`${apiGBDashboardUrl}/api/support/LoadCSVToBQ`, data);
    },
    //#endregion

    //#region Instant Bet
    getActiveCompetitionEventList: (data) => {
        return post(`${apiGBDashboardUrl}/api/obum/getActiveCompetitionEventList`, data);
    },
    //#endregion

    //#region MLTagging Wager
    getMLTaggingWagerList: (data) => {
        return post(`${apiGBDashboardUrl}/api/memberlevelreport/GetMLTaggingWagerSummaryList`, data);
    },

    getMLTaggingWagerDayRangeList: () => {
        return get(`${apiGBDashboardUrl}/api/memberlevelreport/GetMLTaggingWagerDayRangeList`);
    },

    getMLTaggingWagerModelList: () => {
        return get(`${apiGBDashboardUrl}/api/memberlevelreport/GetMLTaggingWagerModelList`);
    },
    //#endregion

    //#region MLTagging UI
    getMLTaggingUIList: (data) => {
        return post(`${apiGBDashboardUrl}/api/memberlevelreport/GetMLTaggingUISummaryList`, data);
    },

    getMLTaggingUIModelList: () => {
        return get(`${apiGBDashboardUrl}/api/memberlevelreport/GetMLTaggingUIModelList`);
    },

    getMLTaggingUISuggestedCategoryList: () => {
        return get(`${apiGBDashboardUrl}/api/memberlevelreport/GetMLTaggingUISuggestedCategoryList`);
    },

    getMLTaggingUIDifferentSuggestedList: () => {
        return get(`${apiGBDashboardUrl}/api/memberlevelreport/GetMLTaggingUIDifferentSuggestedList`);
    },
    //#endregion

    //#region Arber Report
    getArberBetList: (data) => {
        return post(`${apiGBDashboardUrl}/api/WagerLevelReport/GetArberBetList`, data);
    },
    getArberBetListByArberBetID: (data) => {
        return post(`${apiGBDashboardUrl}/api/WagerLevelReport/GetArberBetListByArberBetID`, data);
    },
    getArberWagerActionReasonList: () => {
        return get(`${apiGBDashboardUrl}/api/WagerLevelReport/GetArberBetWagerActionReasonList`);
    },
    getArberWagerActionList: () => {
        return get(`${apiGBDashboardUrl}/api/WagerLevelReport/GetArberBetWagerActionList`);
    },
    //#endregion

    //#region BetAttempt
    getBetAttemptByEventList: (data) => {
        return post(`${apiGBDashboardUrl}/api/riskmanagement/GetBetAttemptByEventList`, data);
    },
    getBetAttemptByBetList: (data) => {
        return post(`${apiGBDashboardUrl}/api/riskmanagement/GetBetAttemptByBetList`, data);
    },
    getBetAttemptSystemRejectionDetailList: (data) => {
        return post(`${apiGBDashboardUrl}/api/riskmanagement/GetBetAttemptSystemRejectionDetailList`, data);
    },
    //#endregion

    //#region Data Download
    dataDownloadDateRange: (data) => {
        return post(`${apiGBDashboardUrl}/api/DataDownload/DataDownloadDateRange`, data);
    },
    getDataDownloadDateRangeReportNameList: () => {
        return get(`${apiGBDashboardUrl}/api/DataDownload/GetDataDownloadDateRangeReportNameList`);
    },
    getDataDownloadDateRangeReportTypeList: () => {
        return get(`${apiGBDashboardUrl}/api/DataDownload/GetDataDownloadDateRangeReportTypeList`);
    },
    getDataDownloadDateRangeReportSportList: () => {
        return get(`${apiGBDashboardUrl}/api/DataDownload/GetDataDownloadDateRangeReportSportList`);
    },
    //#endregion

    //#region Data Download
    getEventReconReport: (data) => {
        return post(`${apiGBDashboardUrl}/api/DataReconReport/GetEventReconReport`, data);
    },
    getAGBMemberSummaryReconReport: (data) => {
        return post(`${apiGBDashboardUrl}/api/DataReconReport/GetAGBMemberSummaryReconReport`, data);
    },
    //#endregion

    //#region MemberCategorySetting
    getDefaultMemberCategoryGroup: () => {
        return get(`${apiGBDashboardUrl}/api/MemberSetting/GetDefaultMemberCategoryGroup`);
    },
    getMemberCategoryGroupList: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/GetMemberCategoryGroupList`, data);
    },
    getMemberCategoryGroupByID: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/GetMemberCategoryGroupByID`, data);
    },
    createMemberCategoryGroup: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/CreateMemberCategoryGroup`, data);
    },
    updateMemberCategoryGroupByID: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/UpdateMemberCategoryGroupByID`, data);
    },
    validateMemberCategoryGroup: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/ValidateMemberCategoryGroup`, data);
    },
    validateMemberCategoryGroupHierarchy: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/ValidateMemberCategoryGroupHierarchy`, data);
    },
    //#endregion

    //#region ComboGhost
    getHighExposureList: (data) => {
        return post(`${apiGBDashboardUrl}/api/WagerLevelReport/getHighExposureList`, data);
    },
    getFullCoverList: (data) => {
        return post(`${apiGBDashboardUrl}/api/WagerLevelReport/getFullCoverList`, data);
    },
    getFullCoverAlertList: (data) => {
        return post(`${apiGBDashboardUrl}/api/WagerLevelReport/getFullCoverAlertList`, data);
    },
    getHighExposureAlertList: (data) => {
        return post(`${apiGBDashboardUrl}/api/WagerLevelReport/getHighExposureAlertList`, data);
    },
    //#endregion

    //#region Internal Audit
    getInternalAuditStatusList: (data) => {
        return get(`${apiGBDashboardUrl}/api/RiskManagement/GetInternalAuditStatusList`);
    },
    getSFUserRolesList: (data) => {
        return get(`${apiGBDashboardUrl}/api/RiskManagement/GetSFUserRolesList`);
    },
    getInternalAuditActionLogList: (data) => {
        return get(`${apiGBDashboardUrl}/api/RiskManagement/GetInternalAuditActionLogList`);
    },
    getInternalAuditEventResultLogListByUser: (data) => {
        return post(`${apiGBDashboardUrl}/api/RiskManagement/GetInternalAuditEventResultLogListByUser`, data);
    },
    getInternalAuditEventResultLogListByMonth: (data) => {
        return post(`${apiGBDashboardUrl}/api/RiskManagement/GetInternalAuditEventResultLogListByMonth`, data);
    },
    getInternalAuditEventResultLogListByDay: (data) => {
        return post(`${apiGBDashboardUrl}/api/RiskManagement/getInternalAuditEventResultLogListByDay`, data);
    },
    getInternalAuditEventResultLogListByMember: (data) => {
        return post(`${apiGBDashboardUrl}/api/RiskManagement/getInternalAuditEventResultLogListByMember`, data);
    },
    //#endregion

    //#region Notification Controller
    getCurrentNotificationList: (data) => {
        return post(`${apiGBDashboardUrl}/api/Notification/GetCurrentNotificationList`, data);
    },
    getArchivedNotificationListByUser: (data) => {
        return post(`${apiGBDashboardUrl}/api/Notification/GetArchivedNotificationListByUser`, data);
    },
    getNotificationModuleList: () => {
        return get(`${apiGBDashboardUrl}/api/Notification/GetNotificationModuleList`);
    },
    createNotificationUserList: (data) => {
        return post(`${apiGBDashboardUrl}/api/Notification/CreateNotificationUserList`, data);
    },
    //#endregion

    //#region One Sided Event
    getOneSidedEventList: (data) => {
        return post(`${apiGBDashboardUrl}/api/EventLevelReport/GetOneSidedEventList`, data);
    },
    getOneSidedEventWagerDetailList: (data) => {
        return post(`${apiGBDashboardUrl}/api/EventLevelReport/GetOneSidedEventWagerDetailList`, data);
    },
    getOneSidedEventCompetitionList: (data) => {
        return post(`${apiGBDashboardUrl}/api/EventLevelReport/GetOneSidedEventCompetitionList`, data);
    },
    getOneSidedEventAlertList: (data) => {
        return post(`${apiGBDashboardUrl}/api/EventLevelReport/GetOneSidedEventAlertList`, data);
    },
    //#endregion

    //#region User
    getUserList: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/GetUserList`, data);
    },
    getUsernameList: () => {
        return get(`${apiGBDashboardUrl}/api/SystemSetting/GetUsernameList`);
    },
    getUserByID: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/GetUserByID`, data);
    },
    checkUserIsSupport: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/CheckUserIsSupport`, data);
    },
    deleteUserByID: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/DeleteUserByID`, data);
    },
    getUserTypeList: () => {
        return get(`${apiGBDashboardUrl}/api/SystemSetting/GetUserTypeList`);
    },
    getUserAuditLogFieldTypeDll: () => {
        return get(`${apiGBDashboardUrl}/api/SystemSetting/getUserAuditLogFieldTypeDll`);
    },
    getUserAuditLogList: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/GetUserAuditLogList`, data);
    },
    getUserAuditLogDetail: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/GetUserAuditLogDetail`, data);
    },
    createUser: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/CreateUser`, data);
    },
    updateUserByID: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/UpdateUserByID`, data);
    },
    getUserStatusList: () => {
        return get(`${apiGBDashboardUrl}/api/systemsetting/GetUserStatusList`);
    },
    getUserListForMemberProfilingAssignment: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/GetUserListForMemberProfilingAssignment`, data);
    },
    getRoleListForUser: (data) => {
        return post(`${apiGBDashboardUrl}/api/systemsetting/GetRoleListForUser`, data);
    },
    //#endregion

    //#region Member Profiling Assignment
    getMemberProfilingAssignmentStatusList: () => {
        return get(`${apiGBDashboardUrl}/api/MemberSetting/GetMemberProfilingAssignmentStatusList`);
    },
    getMemberProfilingAssignmentList: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/GetMemberProfilingAssignmentList`, data);
    },
    getMemberProfilingAssignmentSortByList: () => {
        return get(`${apiGBDashboardUrl}/api/MemberSetting/GetMemberProfilingAssignmentSortByList`);
    },
    createMemberProfilingAssignmentRequest: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/CreateMemberProfilingAssignmentRequest`, data);
    },
    validateCreateMemberProfilingAssignmentRequest: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/ValidateCreateMemberProfilingAssignmentRequest`, data);
    },
    getMemberProfilingAssignmentSummaryList: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/GetMemberProfilingAssignmentSummaryList`, data);
    },
    getMemberProfilingAssignmentByID: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/GetMemberProfilingAssignmentByID`, data);
    },
    updateMemberProfilingAssignment: (data) => {
        return post(`${apiGBDashboardUrl}/api/MemberSetting/UpdateMemberProfilingAssignment`, data);
    },
    //endregion

    // #region FA Member Blacklist
    checkFAMemberBlacklistExists: (data) => {
        return post(`${apiGBDashboardUrl}/api/SystemSetting/CheckFAMemberBlacklistExists`, data);
    },
    createFAMemberBlacklist: (data) => {
        return post(`${apiGBDashboardUrl}/api/SystemSetting/CreateFAMemberBlacklist`, data);
    },
    updateFAMemberBlacklistById: (data) => {
        return post(`${apiGBDashboardUrl}/api/SystemSetting/UpdateFAMemberBlacklistById`, data);
    },
    //#endregion
};
export { apiGBDashboard };
